import React, { useState, useEffect, useRef, useContext } from "react";
import { message } from "antd";
import "../../../assets/style/Home/Home_comp/Map.scss";
import UserItem from "./UserItem/index";
import MyUserItem from "./MyUserItem/index";
import ZoneItem from "./ZoneItem/index";
import * as map_utils from "../../../utils/map_utils.js";
import PubSub from "pubsub-js";
import { getUserInfo } from "../../../request/request.js";
// import { useDispatch, useSelector } from "react-redux";
import { MyContext } from "../../../Context/context";

let detail_showing_throttle = new Date().getTime();
let tip_shaking_throttle = new Date().getTime();

const MyMap = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  let { myUser_data } = props;
  const mycontext = useContext(MyContext);

  const onblur_tip_ref = useRef();

  const [key_status, set_key_status] = useState("");
  const [key_listener, set_key_listener] = useState(false);
  const [other_user_data_map, set_other_user_data_map] = useState(new Map());
  // 因为返回的虚拟dom中无法遍历Map,所以定义一个状态值，存储Map转成的数组
  const [other_user_data_arr, set_other_user_data_arr] = useState([]);
  const [zone_data_arr, set_zone_data_arr] = useState([]);
  const [map_size, set_map_size] = useState();
  const [keyboard_map, set_keyboard_map] = useState();
  const [zone_data_update_stateObj, set_zone_data_update_stateObj] = useState();
  const [zone_out_stateObj, set_zone_out_stateObj] = useState();
  const [new_zone_stateObj, set_new_zone_stateObj] = useState();
  const [keyboard_interact_stateObj, set_keyboard_interact_stateObj] = useState();
  const [show_zoneName, set_show_zoneName] = useState(false);
  const [other_user_data_update_stateObj, set_other_user_data_update_stateObj] = useState(false);
  const [other_user_info_map, set_other_user_info_map] = useState(new Map());
  const [in_zone_info_zoneId, set_in_zone_info_zoneId] = useState(null);
  const [apply_zone_info_zoneId, set_apply_zone_info_zoneId] = useState(null);

  const keyboard_change_myUser_data = (target_data) => {
    PubSub.publish("keyboard_change_myUser_data", target_data);
  };

  // 修改Context
  useEffect(() => {
    mycontext.set_context_other_user_data_map(other_user_data_map);
    PubSub.publish("map_init_other_user", {
      user_data_arr: other_user_data_arr,
    });
  }, [other_user_data_arr]);

  // 根据myUser_data更新个人位置
  // 初始化地图
  useEffect(() => {
    let my_user_dom = document.querySelector(".my_user_container");

    // 初始化本人dom节点
    map_utils.init_user(my_user_dom, myUser_data);
  }, [myUser_data]);

  // 订阅消息以及处理
  useEffect(() => {
    // 键盘对应操作
    let temp_keyboard_map = new Map();
    temp_keyboard_map.set("ArrowLeft", {
      orient: "x",
      length: -1,
      isMove: true,
    });
    temp_keyboard_map.set("ArrowRight", { orient: "x", length: 1, isMove: true });
    temp_keyboard_map.set("ArrowUp", { orient: "y", length: -1, isMove: true });
    temp_keyboard_map.set("ArrowDown", { orient: "y", length: 1, isMove: true });
    temp_keyboard_map.set("KeyA", { orient: "x", length: -1, isMove: true });
    temp_keyboard_map.set("KeyD", { orient: "x", length: 1, isMove: true });
    temp_keyboard_map.set("KeyW", { orient: "y", length: -1, isMove: true });
    temp_keyboard_map.set("KeyS", { orient: "y", length: 1, isMove: true });
    temp_keyboard_map.set("Space", { key_name: "space", isMove: false });
    temp_keyboard_map.set("ShiftLeft", { key_name: "shift", isMove: false });
    temp_keyboard_map.set("ShiftRight", { key_name: "shift", isMove: false });
    temp_keyboard_map.set("KeyR", { key_name: "R", isMove: false });
    temp_keyboard_map.set("KeyQ", { key_name: "Q", isMove: false });
    temp_keyboard_map.set("KeyC", { key_name: "C", isMove: false });

    set_keyboard_map(temp_keyboard_map);

    // 订阅detail展示情况消息
    let detail_pubsub_token = PubSub.subscribe("detail_showing", (_, stateObj) => {
      if (!stateObj.isShowing) {
        // console.log('来这才对');
        // set_key_listener(true)
      } else {
        // console.log('来这了?');
        set_key_listener(false);
      }
    });

    let typing_word_token = PubSub.subscribe("typing_word", (_, stateObj) => {
      if (!stateObj.isTyping) {
        // console.log('来这才对');
        set_key_listener(true);
      } else {
        // console.log('来这了?');
        set_key_listener(false);
      }
    });

    // 订阅传送用户
    let transfer_myUser_position_token = PubSub.subscribe("init_myUser", (_, stateObj) => {
      let my_user_dom = document.querySelector(".my_user_container"),
        map_main = document.querySelector(".map_main"),
        map_view = document.querySelector(".map_view");
      keyboard_change_myUser_data({
        x: stateObj.x,
        y: stateObj.y,
        vision: stateObj.vision,
        exist: stateObj.exist,
      });
      setTimeout(() => {
        // 居中设置
        map_utils.center_setting(my_user_dom, map_view, map_main);
      }, 500);
    });

    // 订阅初始化用户
    let init_myUser_token = PubSub.subscribe("init_myUser_first", (_, stateObj) => {
      let my_user_dom = document.querySelector(".my_user_container"),
        map_main = document.querySelector(".map_main"),
        map_view = document.querySelector(".map_view");
      let { x, y, vision, exist } = stateObj.my_user_info;
      keyboard_change_myUser_data({
        x: x,
        y: y,
        vision: vision,
        exist: exist,
      });
      setTimeout(() => {
        // 居中设置
        map_utils.center_setting(my_user_dom, map_view, map_main);
      }, 500);
    });

    // 订阅初始化地图
    let init_map_token = PubSub.subscribe("init_map", (_, stateObj) => {
      let map_main = document.querySelector(".map_main");
      // console.log(stateObj.map_info.sizeX);
      map_utils.init_map(map_main, stateObj.map_info.sizeX);
      set_map_size(stateObj.map_info.sizeX);
      set_zone_data_arr(stateObj.zone_info);
    });

    // 订阅修改其他用户
    // let init_other_user_token = PubSub.subscribe('init_other_user', (_, stateObj) => {
    //   // 根据userId进行排序
    //   stateObj.other_user_info_arr.sort((a, b) =>
    //     a.user.userId - b.user.userId
    //   )
    //   set_other_user_data_arr(stateObj.other_user_info_arr)
    // })

    let new_zone_token = PubSub.subscribe("new_zone", (_, stateObj) => {
      set_new_zone_stateObj(stateObj);
    });

    let zone_init_token = PubSub.subscribe("zone_init", (_, stateObj) => {
      set_zone_data_update_stateObj(stateObj);
    });

    let zone_out_token = PubSub.subscribe("zone_out", (_, stateObj) => {
      set_zone_out_stateObj(stateObj);
    });

    let out_of_current_space_token = PubSub.subscribe("out_of_current_space", (_, stateObj) => {
      set_other_user_data_map(new Map());
      set_other_user_data_arr([]);
    });

    let keyboard_interact_token = PubSub.subscribe("keyboard_interact", (_, stateObj) => {
      set_keyboard_interact_stateObj(stateObj);
    });

    // 订阅修改其他用户
    let change_other_user_token = PubSub.subscribe("change_other_user", (_, stateObj) => {
      set_other_user_data_update_stateObj(stateObj);
    });

    // 进入某个区域
    let zone_in_token = PubSub.subscribe("zone_in", (_, stateObj) => {
      set_in_zone_info_zoneId(stateObj.zone_info.zone.zoneId);
    });

    // 进入一个还不能加入的区域
    let zone_in_deny_token = PubSub.subscribe("zone_in_deny", (_, stateObj) => {
      set_apply_zone_info_zoneId(stateObj.zone_info.zoneId);
    });

    return () => {
      PubSub.unsubscribe(detail_pubsub_token);
      PubSub.unsubscribe(typing_word_token);
      PubSub.unsubscribe(transfer_myUser_position_token);
      PubSub.unsubscribe(init_map_token);
      PubSub.unsubscribe(init_myUser_token);
      // PubSub.unsubscribe(init_other_user_token)
      PubSub.unsubscribe(zone_init_token);
      PubSub.unsubscribe(zone_out_token);
      PubSub.unsubscribe(new_zone_token);
      PubSub.unsubscribe(keyboard_interact_token);
      PubSub.unsubscribe(change_other_user_token);
      PubSub.unsubscribe(out_of_current_space_token);
      PubSub.unsubscribe(zone_in_token);
      PubSub.unsubscribe(zone_in_deny_token);
    };
  }, []);

  // 更新用户状态
  useEffect(() => {
    {
      let stateObj = other_user_data_update_stateObj;
      if (stateObj) {
        if (stateObj === undefined) return;
        let { other_user_info_arr } = stateObj;

        if (stateObj.typeStr === "change") {
          other_user_info_arr.forEach(async (change_element) => {
            // 防止没人的时候后台返回site_remove导致报错
            if (!!change_element?.userId) {
              change_element.user = await get_user_data(change_element.userId);
              other_user_data_map.set(change_element.userId, change_element);
            }
          });
          setTimeout(() => {
            set_other_user_data_map(other_user_data_map);
            set_other_user_data_arr([...other_user_data_map.values()]);
          }, 0);
        } else if (stateObj.typeStr === "remove") {
          other_user_info_arr.forEach((remove_element) => {
            // 若Map中找该userId，然后delete
            if (other_user_data_map.get(remove_element?.userId)) {
              other_user_data_map.delete(remove_element?.userId);
            } else {
              console.log("error: user site remove error");
            }
          });
          // console.log("remove", other_user_data_map);
          set_other_user_data_map(other_user_data_map);
          set_other_user_data_arr([...other_user_data_map.values()]);
        }
      }
    }
  }, [other_user_data_update_stateObj]);

  const get_user_data = async (userId) => {
    const info_obj = other_user_info_map.get(userId);
    if (!info_obj) {
      // 请求 赋值info_obj
      try {
        const res = await getUserInfo(userId);
        other_user_info_map.set(userId, res);
        set_other_user_info_map(other_user_info_map);
        return res;
      } catch (err) {
        console.error(err);
      }
    } else {
      return info_obj;
    }
  };

  // 监听键盘
  useEffect(() => {
    if (keyboard_interact_stateObj) {
      if (keyboard_interact_stateObj.type === "shift") {
        set_show_zoneName(!show_zoneName);
      }
    }
  }, [keyboard_interact_stateObj]);

  // 接收到新增的区域
  useEffect(() => {
    if (new_zone_stateObj) {
      let temp_zone_data_arr = zone_data_arr;
      // console.log(temp_zone_data_arr);
      temp_zone_data_arr.push(new_zone_stateObj.new_zone_info);
      // console.log(temp_zone_data_arr);
      set_zone_data_arr(temp_zone_data_arr);
    }
  }, [new_zone_stateObj]);

  // 更新区域数据后触发函数
  useEffect(() => {
    let temp_zone_data_arr = zone_data_arr;
    if (zone_data_update_stateObj) {
      // 根据zoneList来增加区域信息
      let temp_zone_arr = [...temp_zone_data_arr, ...zone_data_update_stateObj.zone_init_info.zoneList];
      let delete_arr = zone_data_update_stateObj.zone_init_info.zoneRemoveList;
      // 根据zoneRemoveList来删除区域信息
      let filter_result_arr = temp_zone_arr.filter((zone_info_item) => !delete_arr.includes(zone_info_item.zoneId));
      temp_zone_data_arr = filter_result_arr;
      // 置空
      set_zone_data_update_stateObj();
    }
    if (zone_out_stateObj) {
      // --进出zone样式辅助处理
      if (zone_out_stateObj.zone_info.zoneOutList.includes(in_zone_info_zoneId)) {
        set_in_zone_info_zoneId(null);
      }
      if (zone_out_stateObj.zone_info.zoneOutList.includes(apply_zone_info_zoneId)) {
        set_apply_zone_info_zoneId(null);
      }
      // --进出zone逻辑处理
      let delete_arr;
      if (zone_out_stateObj.zone_info.zoneRemoveList)
        delete_arr = [...zone_out_stateObj.zone_info.zoneRemoveList, ...zone_out_stateObj.zone_info.zoneOutList];
      else delete_arr = [...zone_out_stateObj.zone_info.zoneOutList];
      let filter_result_arr = temp_zone_data_arr.filter(
        (zone_info_item) =>
          !delete_arr.includes(zone_info_item.zoneId) || zone_info_item.zoneId === delete_arr[delete_arr.length - 1]
      );
      temp_zone_data_arr = filter_result_arr;
      // 置空
      set_zone_out_stateObj();
    }
    set_zone_data_arr(temp_zone_data_arr);
  }, [zone_data_update_stateObj, zone_out_stateObj]);

  // 监控键盘
  useEffect(() => {
    let now = new Date().getTime();
    if (now - detail_showing_throttle > 250) {
      let my_user_dom = document.querySelector(".my_user_container"),
        map_main = document.querySelector(".map_main"),
        map_view = document.querySelector(".map_view");

      let keyboard_code_obj = keyboard_map?.get(key_status.code);

      if (keyboard_code_obj) {
        if (keyboard_code_obj.isMove) {
          // 判断移动方向以及计算移动后的值
          let temp_myUser_data_orient = myUser_data[keyboard_code_obj.orient] + keyboard_code_obj.length;
          // 判断还在地图内
          if (temp_myUser_data_orient >= 0 && temp_myUser_data_orient <= map_size - 1) {
            myUser_data[keyboard_code_obj.orient] += keyboard_code_obj.length;
            map_utils.move_myUser(my_user_dom, myUser_data, map_view, map_main);
            keyboard_change_myUser_data(myUser_data);
          } else {
            message.destroy();
            message.warning("无法走出地图以外区域");
          }
        } else {
          if (keyboard_code_obj.key_name) {
            PubSub.publish("keyboard_interact", {
              type: keyboard_code_obj.key_name,
            });
          }
        }
      }
      detail_showing_throttle = new Date().getTime();
    }
  }, [key_status]);

  let change_key_status = (e) => {
    set_key_status(e);
  };

  const detail_showing_keyup = (e) => {
    const move_key_code = [
      "ArrowLeft",
      "KeyA",
      "ArrowRight",
      "KeyD",
      "ArrowUp",
      "KeyW",
      "ArrowDown",
      "KeyS",
      "Space",
      "ShiftLeft",
      "ShiftRight",
      "KeySR",
      "KeySQ",
      "KeySC",
    ];
    if (move_key_code.includes(e.code)) {
      // 节流
      let now = new Date().getTime();
      if (now - tip_shaking_throttle > 1400) {
        onblur_tip_ref.current.classList.add("shaking");
        setTimeout(() => {
          onblur_tip_ref.current.classList.remove("shaking");
        }, 1100);
        tip_shaking_throttle = new Date().getTime();
      }
    }
  };

  // 判断何时需要禁止监听键盘
  useEffect(() => {
    // console.log(window);
    if (key_listener) {
      window.onkeydown = change_key_status;
    } else {
      window.onkeydown = detail_showing_keyup;
    }
  }, [key_listener]);

  const map_on_focus = () => {
    // console.log('地图获得焦点');
    set_key_listener(true);
  };

  const map_on_blur = () => {
    // console.log('地图失去焦点');
    set_key_listener(false);
  };

  return (
    <>
      <div className="Map_coverBox">
        <div className={key_listener ? "circle_bc" : "circle_bc map_onblur"}>
          <span className="onblur_tip" ref={onblur_tip_ref}>
            点击地图以获得按键控制权限
          </span>
        </div>
        <div className="map_wrapper">
          <div className="map_box" tabIndex="0" onFocus={map_on_focus} onBlur={map_on_blur}>
            <div className="map_view">
              <div className="map_main" style={{ left: "0px", top: "0px" }}>
                {other_user_data_arr.map((v, i, a) => {
                  return (
                    <UserItem
                      main_data={{
                        x: v.x,
                        y: v.y,
                        exist: v.exist,
                        vision: v.vision,
                      }}
                      nickName={v.user.nickName}
                      key={v.user.userId}
                      avatar={v.user.avatar}
                      userId={v.user.userId}
                    />
                  );
                })}
                {/* <UserItem
                  main_data={{
                    x: 26,
                    y: 19,
                    exist: 5,
                    vision: 5,
                  }}
                  nickName="testRobot"
                  key={999}
                  isTest={true}
                  avatar={null}
                  userId={999}
                /> */}
                {zone_data_arr.map((v, index, a) => {
                  return (
                    <ZoneItem
                      key={v.zoneId}
                      zone_info={{
                        endX: v.endX,
                        endY: v.endY,
                        startX: v.startX,
                        startY: v.startY,
                        zoneName: v.zoneName,
                      }}
                      zoneType={v.zoneType}
                      show_zoneName={show_zoneName}
                      zone_tag={
                        apply_zone_info_zoneId === v.zoneId
                          ? "deny_enter_zone"
                          : in_zone_info_zoneId === v.zoneId
                          ? "gonna_enter_zone"
                          : ""
                      }
                    ></ZoneItem>
                  );
                })}
                <MyUserItem />
              </div>
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default MyMap;
