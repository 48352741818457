import React, { useEffect, useState } from "react";
import "../../../assets/style/Home/Home_comp/KeyGroup.scss";
import KeyItem from "./KeyItem";
import { PubSub } from "pubsub-js";
import { postGroupRequest } from "../../../request/request.js";
import { message } from "antd";

export default function KeyGroup(props) {
  const { keygroup_ref } = props;

  const [in_zone_info, set_in_zone_info] = useState();
  const [apply_zone_info, set_apply_zone_info] = useState({});
  const [zone_out_stateObj, set_zone_out_stateObj] = useState();
  const [keyboard_interact_stateObj, set_keyboard_interact_stateObj] = useState();
  const [apply_in_showing, set_apply_in_showing] = useState(false);

  useEffect(() => {
    let zone_in_token = PubSub.subscribe("zone_in", (_, stateObj) => {
      set_in_zone_info(stateObj.zone_info);
    });

    let zone_in_deny_token = PubSub.subscribe("zone_in_deny", (_, stateObj) => {
      set_apply_in_showing(true);
      set_apply_zone_info(stateObj.zone_info);
    });

    let zone_out_token = PubSub.subscribe("zone_out", (_, stateObj) => {
      set_zone_out_stateObj(stateObj);
    });

    let keyboard_interact_token = PubSub.subscribe("keyboard_interact", (_, stateObj) => {
      set_keyboard_interact_stateObj(stateObj);
    });

    return () => {
      PubSub.unsubscribe(zone_in_token);
      PubSub.unsubscribe(zone_in_deny_token);
      PubSub.unsubscribe(zone_out_token);
      PubSub.unsubscribe(keyboard_interact_token);
    };
  }, []);

  // 处理zone_out
  useEffect(() => {
    if (zone_out_stateObj) {
      PubSub.publish("chat_status", {
        chatting: false,
        targetType: "ZONE",
        zoneOutList: zone_out_stateObj.zone_info.zoneOutList,
      });
      if (zone_out_stateObj.zone_info.zoneOutList.includes(apply_zone_info?.zoneId)) {
        set_apply_in_showing(false);
        set_apply_zone_info();
      }
      if (zone_out_stateObj.zone_info.zoneOutList.includes(in_zone_info?.zone.zoneId)) {
        set_in_zone_info();
      }
    }
  }, [zone_out_stateObj]);

  // 处理键盘监听
  useEffect(() => {
    if (keyboard_interact_stateObj) {
      console.log(keyboard_interact_stateObj.type);
      if (keyboard_interact_stateObj.type === "R" && apply_in_showing) {
        resolve_apply();
      }
      if (keyboard_interact_stateObj.type === "space") {
        resolve_join();
        set_in_zone_info();
      }
      if (keyboard_interact_stateObj.type === "Q") {
        PubSub.publish("exit_zone", { exit: true });
      }
      if (keyboard_interact_stateObj.type === "C") {
        PubSub.publish("chat_status", { chatting: false, targetType: "FRIEND" });
        PubSub.publish("chat_status", { chatting: false, targetType: "TEMP" });
        PubSub.publish("end_chat", { end: true });
      }
    }
  }, [keyboard_interact_stateObj]);

  // 确定加入聊天
  const resolve_join = () => {
    if (in_zone_info) {
      PubSub.publish("zone_in_ack", { state: true });
      PubSub.publish("chat_status", { chatting: true, targetType: "ZONE", zone_info: in_zone_info.zone });
    } else {
      message.destroy();
      message.warning("当前没有可加入的区域");
    }
  };

  // 确定申请进入群组
  const resolve_apply = () => {
    // console.log(apply_zone_info);
    let senderId = parseInt(sessionStorage.getItem("userId"));
    let targetId = apply_zone_info.groupId;

    let sendForm = new FormData();
    sendForm.append("senderId", senderId);
    sendForm.append("targetId", targetId);
    postGroupRequest(sendForm)
      .then((res) => {
        // console.log(res);
        message.success("群组申请发送成功");
        set_apply_in_showing(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    // <div className='keyGroup_box'>
    //     <KeyItem keyText='space' keyIntro='进入区域' />
    //     <KeyItem keyText='r' keyIntro='申请进入区域11111111' />
    //     <KeyItem keyText='q' keyIntro='退出区域' />
    // </div>
    <div className="keyGroup_list_box" ref={keygroup_ref}>
      <KeyItem keyText="Space" keyIntro="进入区域" baColor="#fff2cc" isActive={in_zone_info} />
      <KeyItem keyText="Q" keyIntro="退出区域" baColor="#f5f5f5" isActive={true} />
      <KeyItem keyText="C" keyIntro="退出私聊" baColor="#e1d5e7" isActive={true} />
      <KeyItem keyText="Shift" keyIntro="显示区域名" baColor="#ffe6cc" isActive={true} />
      {/* <KeyItem keyText='O' keyIntro='打开箱子' baColor='#f8cecc'/> */}
      {apply_in_showing ? (
        <KeyItem
          keyText="R"
          //   keyIntro={`申请进入${'\n'}<${apply_zone_info.groupName}>`}
          keyIntro={
            <>
              <p className="keyItem_apply_enter">申请进入群组</p>
              <u className="keyItem_apply_enter_groupName" title={apply_zone_info.groupName}>{apply_zone_info.groupName}</u>
            </>
          }
          baColor="#dae8fc"
          isActive={true}
        />
      ) : (
        ""
      )}
      {/* <KeyItem keyText='A' keyIntro='自动进入确定' baColor='#d5e8d4'/> */}
    </div>
  );
}
