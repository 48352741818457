import React, { useEffect, useState, useRef } from 'react'
import { PubSub } from 'pubsub-js';
import * as img_util from '../../../../utils/img_handle'

export default function MyUserItem() {


    const [chatting_contant, set_chatting_contant] = useState()
    const [myNickName, set_myNickName] = useState()
    const [myAvatar, set_myAvatar] = useState()

    const bubble_ref = useRef()

    useEffect(() => {
        let my_message_token = PubSub.subscribe('my_message', (_, stateObj) => {
            set_chatting_contant(stateObj.my_message_cont)
            bubble_ref.current.classList.add('showing_bubble')
            setTimeout(() => {
                bubble_ref.current.classList.remove('showing_bubble')
            }, 4000);
        })

        let my_media_info_token = PubSub.subscribe('my_media_info', (_, stateObj) => {
            // console.log(stateObj);
            set_myNickName(stateObj.nickName)
            set_myAvatar(stateObj.avatar)
        })

        return () => {
            PubSub.unsubscribe(my_message_token)
            PubSub.unsubscribe(my_media_info_token)
        }
    }, [])

    return (
        <>
            <div className="user_container my_user_container" style={{ left: "0px", top: "0px" }}>
                <div className="relative_box">
                    <div className="exist_range my_exist_range"></div>
                    <div className="vision_range my_vision_range"></div>
                    <div className="member_avatar">
                        <img src={img_util.img_translate(myAvatar)} alt="" />
                        <div className='user_name'>{myNickName}</div>
                    </div>
                    <div className="chatting_bubble" ref={bubble_ref}>{chatting_contant}</div>
                </div>
            </div>
        </>
    )
}
