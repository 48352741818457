import React, { useEffect, useState, useRef } from "react";
import { PubSub } from "pubsub-js";
import * as img_util from "../../../../utils/img_handle";
import { init_user } from "../../../../utils/map_utils.js";
import test_avatar from "../../../../assets/img/Index/main_character.svg";

export default function UserItem(props) {
  let { nickName, avatar, userId, main_data, isTest, user_ref } = props;

  const [chatting_contant, set_chatting_contant] = useState();

  const bubble_ref = useRef();
  const other_user_ref = useRef();

  //   修改自身位置
  useEffect(() => {
    // console.log(other_user_ref.current);
    init_user(other_user_ref.current, main_data);
  }, [main_data]);

  //   监听有无发送消息
  useEffect(() => {
    let my_message_token = PubSub.subscribe("some_user_message", (_, stateObj) => {
      // console.log(stateObj,userId);

      if (userId === stateObj.senderId) {
        set_chatting_contant(stateObj.my_message_cont);
        bubble_ref.current.classList.add("showing_bubble");
        setTimeout(() => {
          bubble_ref.current.classList.remove("showing_bubble");
        }, 4000);
      }
    });

    return () => {
      PubSub.unsubscribe(my_message_token);
    };
  }, []);

  const other_vision_range_ref = useRef();

  const show_vision_range = () => {
    other_vision_range_ref.current.classList.add("show_vision_range");
  };

  const hide_vision_range = () => {
    other_vision_range_ref.current.classList.remove("show_vision_range");
  };

  return (
    <div
      className="user_container other_user_container"
      style={{ left: "0px", top: "0px" }}
      key={userId}
      ref={other_user_ref}
    >
      <div className="relative_box">
        <div className="exist_range"></div>
        <div className="vision_range" ref={other_vision_range_ref}></div>
        <div className="member_avatar" onMouseEnter={show_vision_range} onMouseLeave={hide_vision_range} ref={user_ref}>
          {isTest ? <img src={test_avatar} alt="" /> : <img src={img_util.img_translate(avatar)} alt="" />}
          <div className="user_name">{nickName}</div>
        </div>
        <div className="chatting_bubble" ref={bubble_ref}>
          {chatting_contant}
        </div>
      </div>
    </div>
  );
}
