import React, { useRef, useState, useEffect } from 'react'
import '../../../../assets/style/Home/Home_comp/OtherT/Input_box.scss'
import { Dropdown, Button, Steps, Popover } from 'antd';
import { PubSub } from 'pubsub-js';
import { message } from 'antd';

export default function Input_box() {

    const [showing, set_showing] = useState(false);
    // 已经进入的区域数组（嵌套区域1->2->3
    const [in_zone_arr, set_in_zone_arr] = useState([]);
    const [chat_status_stateObj, set_chat_status_stateObj] = useState();
    const [is_chat_someone, set_is_chat_someone] = useState(false);
    const [is_chat_zone, set_is_chat_zone] = useState(false);
    const [is_chat_friend, set_is_chat_friend] = useState(false);
    const [chat_to_obj, set_chat_to_obj] = useState('临时聊天');
    const [obj_map, set_obj_map] = useState();

    const input_box_input_ref = useRef();
    const zone_line_box_ref = useRef();

    const send_my_message = () => {
        let sending_cont = input_box_input_ref.current.value;
        if (sending_cont !== '') {
            let targetTypeString = chat_to_obj === '临时聊天' ? 'TEMP' : chat_to_obj === '好友私聊' ? 'FRIEND' : 'ZONE';

            PubSub.publish('my_message', {
                my_message_cont: sending_cont,
                targetType: targetTypeString,
                zone_sessionId: in_zone_arr[in_zone_arr.length - 1]?.zoneId
            })
            input_box_input_ref.current.value = ""
        } else {
            message.destroy();
            message.warning('无法发送空白消息')
        }

    }

    let check_input_is_enter = (e) => {
        if (e.code === "Enter") {
            send_my_message();
            // console.log(is_chat_someone, is_chat_zone, zone_info);
        }
    }

    useEffect(() => {
        // 非空
        if (chat_status_stateObj) {
            // 区域
            if (chat_status_stateObj.targetType === 'ZONE') {
                // console.log('chat_status_stateObj',chat_status_stateObj.zone_info.zoneId);
                // 确定聊天
                if (chat_status_stateObj.chatting) {
                    set_is_chat_zone(true)
                    // 新进入的区域信息添加进嵌套数组内
                    set_in_zone_arr([...in_zone_arr, chat_status_stateObj.zone_info])
                    set_chat_to_obj('区域群聊');
                } else {
                    // 是否退出某个空间
                    if (chat_status_stateObj.is_out_space) {
                        set_in_zone_arr([])
                        set_is_chat_zone(false)
                    } else {
                        let delete_arr = chat_status_stateObj.zoneOutList,
                            temp_in_zone_arr = in_zone_arr;
                        // 根据zoneOutList来删除嵌套数组内的区域信息
                        let filter_result_arr = temp_in_zone_arr.filter(zone_info_item =>
                            !delete_arr?.includes(zone_info_item.zoneId)
                        )
                        // console.log('filter_result_arr', filter_result_arr);
                        set_in_zone_arr(filter_result_arr);
                        if (filter_result_arr.length === 0) {
                            // 退出聊天
                            set_is_chat_zone(false)
                        }
                    }
                }
            }
            // 私聊
            else if (chat_status_stateObj.targetType === 'TEMP') {
                if (chat_status_stateObj.chatting) {
                    // 确定私聊
                    set_is_chat_someone(true)
                    set_chat_to_obj('临时聊天');
                } else {
                    // 退出私聊
                    set_is_chat_someone(false)
                }
            } else if (chat_status_stateObj.targetType === 'FRIEND') {
                if (chat_status_stateObj.chatting) {
                    // 确定私聊
                    set_is_chat_friend(true)
                    set_chat_to_obj('好友私聊');
                } else {
                    // 退出私聊
                    set_is_chat_friend(false)
                }
            }
        }
    }, [chat_status_stateObj])

    // 控制是否显示聊天输入框以及信息发送对象
    useEffect(() => {
        if (is_chat_someone || is_chat_zone || is_chat_friend) {
            set_showing(true);
        }

        if (is_chat_someone) {
            set_chat_to_obj('临时聊天')
        }

        if (is_chat_friend) {
            set_chat_to_obj('好友私聊')
        }

        if (is_chat_zone) {
            set_chat_to_obj('区域群聊')
        }

        if (!is_chat_someone && !is_chat_zone && !is_chat_friend) {
            set_showing(false);
        }

    }, [is_chat_someone, is_chat_zone, is_chat_friend])

    // 订阅消息
    useEffect(() => {
        let chat_status_token = PubSub.subscribe('chat_status', (_, stateObj) => {
            set_chat_status_stateObj(stateObj)
        })

        return () => {
            PubSub.unsubscribe(chat_status_token);
        }
    }, [])

    // 载入处理初始数据
    useEffect(() => {
        let temp_obj_map = new Map();
        temp_obj_map.set('0', '临时聊天')
        temp_obj_map.set('1', '好友私聊')
        temp_obj_map.set('2', '区域群聊')
        temp_obj_map.set('-1', '退出')
        set_obj_map(temp_obj_map)
    }, [])

    const onClick_dropdown = ({ key }) => {
        let temp_chat_to_obj = obj_map.get(key);
        if (key !== '-1') set_chat_to_obj(temp_chat_to_obj);
        if (key === '-1') {
            let targetTypeString = chat_to_obj === '临时聊天' ? 'TEMP' : chat_to_obj === '好友私聊' ? 'FRIEND' : 'ZONE';
            if (targetTypeString === 'TEMP' || targetTypeString === 'FRIEND') {
                PubSub.publish('chat_status', { chatting: false, targetType: targetTypeString })
                PubSub.publish('end_chat', { end: true })
            }
            else
                PubSub.publish('exit_zone', { exit: true })
        }
    };

    const items = [
        {
            label: (
                <>
                    <span>
                        临时聊天
                    </span>
                </>
            ),
            key: '0',
            disabled: !is_chat_someone,
        },
        {
            label: (
                <>
                    <span>
                        好友私聊
                    </span>
                </>
            ),
            key: '1',
            disabled: !is_chat_friend,
        },
        {
            label: (
                <>
                    <span>
                        区域群聊
                    </span>
                </>
            ),
            key: '2',
            disabled: !is_chat_zone,
        },
        {
            type: 'divider',
        },
        {
            label: (
                <>
                    <span className='out_current_chat'>
                        退出当前聊天
                    </span>
                </>
            ),
            key: '-1',
        },
    ];

    // 区域链更新
    useEffect(()=>{
        if(in_zone_arr){
            let zone_line_box_current = zone_line_box_ref?.current;
            zone_line_box_current.scrollLeft = zone_line_box_current.scrollWidth
        }
    },[in_zone_arr])


    return (
        <>
            <div className='zone_line_box' ref={zone_line_box_ref}>
                <Steps
                    current={in_zone_arr.length - 1}
                    type="inline"
                    // progressDot={customDot}
                    size="small"
                    items={in_zone_arr.map((v, i, a) => {
                        return { title: v.zoneName, description: v.zoneName }
                    })

                    }
                />
            </div>
            <div className={showing ? "input_box_wrapper" : "input_box_wrapper hidden_input_box"}>
                <Dropdown menu={{
                    items,
                    selectable: true,
                    onClick: onClick_dropdown
                }}>
                    <Button>
                        {chat_to_obj}
                    </Button>
                </Dropdown>
                <input
                    type="text"
                    id='input_box_input'
                    onKeyUp={check_input_is_enter}
                    ref={input_box_input_ref}
                    maxLength={40}
                    title='最多支持40个字符' />
                <button id='input_box_btn' onClick={send_my_message}>发送</button>
            </div>
        </>
    )
}
