// 换算单位
let unit_px_rate = 50;

// 头像大小
let avatar_size = 50;

// 加减数值
let value_controller = (number, add_number_unit) => {
    /* 
        number: 原值数值
        add_number: 增量
    */
    number += add_number_unit;
    return number;
}

// 换算基本单位
let translate_into_px = (value) => {
    /* 
        用于将x,y的单位距离换算为px距离
    */
    return value * unit_px_rate;
}

// 换算像素数值
let translate_into_unit = (value) => {
    /* 
        用于将px距离换算为像素数值
    */
    return value / unit_px_rate;
}

// 由dom节点查找data里对应的索引
let dom_into_index = (target_dom, data) => {
    /* 
        target_dom: 要查找的dom节点
        data: 数据集

        return(索引值，不存在返回-1)
    */
    let returnIndex = -1;
    for (let index = 0; index < data.length; index++) {
        if (data[index].userId === target_dom.getAttribute("id"))
            returnIndex = index;
    }

    return returnIndex;
}

// 用于获取某个属性值的数值
let get_attribute = (target_dom, attr_str) => {
    /* 
        封装获取getComputedStyle，直接返回int数值
        attr_str: 要获取的值
        步骤：
        1. 使用getComputedStyle获取字符串'25px'
        2. 使用slice截取除了'px'之外的字符串
        3. 使用parseInt将String转化为Number

        attr_str:
        borderBlockEndWidth: 宽度
        borderBlockEndStyle: 样式(solid)
        borderBlockEndColor: 颜色
        padding: 内边距
    */
    return parseInt(getComputedStyle(target_dom)[attr_str].slice(0, -2));
}

// 用于获取userBox中的某个子节点
let get_childNode = (target_dom, type_str) => {
    /* 
        封装获取userBox中的某个子节点，直接返回节点
        type_str: 节点字符串
    */
    let returnDom;

    switch (type_str) {
        case 'vision': {
            returnDom = target_dom.childNodes[0].childNodes[1];
            break;
        }

        case 'exist': {
            returnDom = target_dom.childNodes[0].childNodes[0];
            break;
        }

        case 'img': {
            returnDom = target_dom.childNodes[0].childNodes[2];
            break;
        }

        default: {
            returnDom = null;
            break
        }
    }

    return returnDom;
}


// 计算真实坐标位置
let translate_real_position = (before_x, before_y, target_dom) => {
    /* 
        由于padding, margin与border会参与坐标的计算，所以需要减去这些数据，获取真实头像所在位置
        before_x:之前的单位x坐标
        before_y:之前的单位y坐标
        target_dom:所要计算的dom节点
        步骤：
            1. 获取目标节点的border与padding
            2. 原来的坐标减去border与padding的值
    */
    let returnObj = {
        'after_X': translate_into_px(before_x),
        'after_Y': translate_into_px(before_y)
    }

    let userSize = get_attribute(target_dom, 'width');
    let change_distance = (userSize - avatar_size) / 2;

    returnObj.after_X -= change_distance;
    returnObj.after_Y -= change_distance;

    return returnObj;
}

// 还原原始坐标位置
let translate_stand_position = (target_dom) => {
    /* 
        target_dom:所要计算的dom节点
        步骤：
            1. 获取目标节点的border与padding
            2. 原来的坐标减去border与padding的值
    */
    let returnObj = {
        'before_X': get_attribute(target_dom, 'left'),
        'before_Y': get_attribute(target_dom, 'top')
    }

    let userSize = get_attribute(target_dom, 'width');
    let change_distance = (userSize - avatar_size) / 2;

    returnObj.before_X += change_distance;
    returnObj.before_Y += change_distance;

    return returnObj;
}

// 移动头像
let move_user = (direction, move_distance_unit, targetDom, data) => {
    /*
        direction: 方向 ['top','left']
        move_distance: 移动距离
        targetDom: 要移动的dom节点
        data: 数值数组
        步骤：
            1. 计算移动后的数值
            2. 将计算后的数值放入节点内联样式中
    */


    if (direction === 'left') {
        data.x = value_controller(data.x, move_distance_unit);
        targetDom.style[direction] = `${get_attribute(targetDom, direction) + unit_px_rate * move_distance_unit}px`
    }

    if (direction === 'top') {
        data.y = value_controller(data.y, move_distance_unit);
        targetDom.style[direction] = `${get_attribute(targetDom, direction) + unit_px_rate * move_distance_unit}px`
    }

    return data;

}

// 移动画布
let move_map_main = (direction, move_distance_unit, map_main) => {
    /*
        direction: 方向 ['top','left']
        move_distance_unit: 移动单位距离
        map_main: 画布
        步骤：
            1. 计算移动后的数值
            2. 将计算后的数值放入内联样式中
    */
    if (direction === 'left') {
        map_main.style[direction] = `${get_attribute(map_main, direction) + unit_px_rate * move_distance_unit}px`
    }

    if (direction === 'top') {
        map_main.style[direction] = `${get_attribute(map_main, direction) + unit_px_rate * move_distance_unit}px`
    }

}

// 修改地图大小
const init_map = (map_dom, size_unit) => {
    map_dom.style.width = `${translate_into_px(size_unit) - 50}px`
    map_dom.style.height = `${translate_into_px(size_unit) - 50}px`
}

// 初始化某个节点
let init_user = (target_dom, data) => {
    
    // 初始化exist和vision
    init_user_ev(target_dom, data);

    // 初始化位置
    init_user_position(target_dom, data);
}

// 初始化节点的exist和vision
let init_user_ev = (target_dom, data) => {

    // 初始化感知范围与视觉范围
    get_childNode(target_dom, 'exist').style.width = `${translate_into_px(data.exist * 2)}px`;
    get_childNode(target_dom, 'exist').style.height = `${translate_into_px(data.exist * 2)}px`;
    get_childNode(target_dom, 'vision').style.width = `${translate_into_px(data.vision * 2)}px`;
    get_childNode(target_dom, 'vision').style.height = `${translate_into_px(data.vision * 2)}px`;

    // user_container大小等于vision和exist中较大值
    let user_size = `${translate_into_px(Math.max(data.exist, data.vision)) * 2}px`;
    target_dom.style.width = user_size;
    target_dom.style.height = user_size;

    // 使在里面的范围居中
    if (data.exist > data.vision) {
        get_childNode(target_dom, 'vision').classList.add('inside_middle');
    } else {
        get_childNode(target_dom, 'exist').classList.add('inside_middle');
    }
}

// 初始化节点的真实位置
let init_user_position = (target_dom, data) => {
    // 计算真实位置
    let translate_after_returnObj = translate_real_position(data.x, data.y, target_dom);

    // 此处减去avatar_size/2是为了使得头像中心落在交点上，如果是在格子内则无需减去
    target_dom.style.left = `${translate_after_returnObj.after_X - avatar_size / 2}px`
    target_dom.style.top = `${translate_after_returnObj.after_Y - avatar_size / 2}px`
}



// 本人头像居中 ---↓---
let center_setting = (target_dom, map_view, map_main) => {
    /* 
        用于将目标节点居中, 且放大至视野范围与可见范围相同
        步骤：
            1. 计算目标节点的宽高、padding与border宽度
            2. 计算map_main的宽高
            3. map_main移动的距离等于[(map宽 - 节点的总宽width] / 2 - 节点的left
    */

    let target_dom_width = get_attribute(target_dom, 'width'),
        target_dom_height = get_attribute(target_dom, 'height'),
        target_dom_left_px = parseInt(target_dom.style.left.slice(0, -2)),
        target_dom_top_px = parseInt(target_dom.style.top.slice(0, -2)),
        map_width = get_attribute(map_view, 'width'),
        map_height = get_attribute(map_view, 'height');

    let adjust_position_left = (map_width - target_dom_width) / 2 - target_dom_left_px;
    let adjust_position_top = (map_height - target_dom_height) / 2 - target_dom_top_px;
    // console.log(adjust_position_left, map_width, target_dom_width, map_left_px, target_dom_left_px);

    map_main.style.left = `${adjust_position_left}px`;
    map_main.style.top = `${adjust_position_top}px`;
    let scale_rate = map_width / get_attribute(get_childNode(target_dom, 'vision'), 'width');
    scale_set_directly(scale_rate, map_view)
}

// 本人头像移动 ---↓---
let move_myUser = (target_dom, data, map_view, map_main) => {
    /* 
        用于移动本人头像
        步骤：
        1. 移动用户头像
        2. 移动map_main
    */
    init_user_position(target_dom, data)
    center_setting(target_dom, map_view, map_main);
}

// 调整视野范围
let set_vision = (targetDom, mydata, vision, map_view, map_main) => {
    /* 
        targetDom: dom节点
        mydata: dom节点相关的信息
        vision: 最新视野范围
    */
    mydata.vision = vision;
    targetDom.classList.add('scaleing_user');
    init_user(targetDom, mydata);
    center_setting(targetDom, map_view, map_main)
    targetDom.classList.remove('scaleing_user');
}

// 其他用户移动
let someone_move = (target_dom, x, y, dataArr) => {
    /* 
        index: dom索引
        x:  更新后x
        y:  更新后y

    */
    init_user_position(target_dom, { x, y });

    let index = dom_into_index(target_dom, dataArr)
    dataArr[index].x = x;
    dataArr[index].y = y;
    return dataArr;
}

// 直接设置放大缩小画面比例
let scale_set_directly = (rate, map_view) => {
    /* 
        rate: 直接设置的放大比例
    */
    map_view.style.transform = `scale(${rate})`
}

// 生成区域
let build_chat_area = (start_x, start_y, end_x, end_y, map_main) => {
    // 增加一个方块的大小
    end_x++;
    end_y++;

    let build_area_width = translate_into_px(end_x - start_x);
    let build_area_height = translate_into_px(end_y - start_y);

    // 生成dom节点
    let build_area_dom = document.createElement('div');
    build_area_dom.classList.add('build_area');
    build_area_dom.style.left = `${translate_into_px(start_x) - unit_px_rate / 2}px`;
    build_area_dom.style.top = `${translate_into_px(start_y) - unit_px_rate / 2}px`;
    build_area_dom.style.width = `${build_area_width}px`;
    build_area_dom.style.height = `${build_area_height}px`;

    // 将dom节点放入map_main
    map_main.appendChild(build_area_dom);
}

// 初始化区域
const init_chat_area = (target_dom, data) => {
    let { endX, endY, startX, startY } = data;
    // 增加一个方块的大小
    endX++;
    endY++;

    let build_area_width = translate_into_px(endX - startX);
    let build_area_height = translate_into_px(endY - startY);

    // 配置信息
    target_dom.style.left = `${translate_into_px(startX) - unit_px_rate / 2}px`;
    target_dom.style.top = `${translate_into_px(startY) - unit_px_rate / 2}px`;
    target_dom.style.width = `${build_area_width}px`;
    target_dom.style.height = `${build_area_height}px`;
}

const calculate_chat_area = (data) => {
    let { endX, endY, startX, startY } = data;
    // 增加一个方块的大小
    endX++;
    endY++;

    let build_area_width = translate_into_px(endX - startX);
    let build_area_height = translate_into_px(endY - startY);

    let returnObj = {};

    // 配置信息
    returnObj.left = `${translate_into_px(startX) - unit_px_rate / 2}px`;
    returnObj.top = `${translate_into_px(startY) - unit_px_rate / 2}px`;
    returnObj.width = `${build_area_width}px`;
    returnObj.height = `${build_area_height}px`;

    return returnObj;
}

// 清除区域
let clean_chat_area = (map_main) => {

    let delete_area_dom_arr = document.querySelectorAll('.build_area');

    for (let index = 0; index < delete_area_dom_arr.length; index++) {
        map_main.removeChild(delete_area_dom_arr[index]);
    }
}

module.exports = {
    init_user,
    init_user_ev,
    init_user_position,
    center_setting,
    move_myUser,
    set_vision,
    someone_move,
    build_chat_area, calculate_chat_area,
    clean_chat_area,
    init_map, init_chat_area
}




