import React, { useState, useEffect } from 'react'
import { Button, message } from 'antd';
import UsernameInput from '../../MyInput/UsernameInput';
import PasswordInput from '../../MyInput/PasswordInput';
import EmailInput from '../../MyInput/EmailInput';
import CodeInput from '../../MyInput/CodeInput';
import { postRegister } from '../../../request/request';
import '../../../assets/style/Login/RegisterBox/index.scss'

export default function RegisterBox(props) {

    const { setIsLogin, isLogin } = props;

    const [userName_register, setUserName_register] = useState();
    const [passWord_register, setPassWord_register] = useState();
    const [email_register, setEmail_register] = useState();
    const [code_register, setCode_register] = useState();

    const moveToLogin = () => {
        setIsLogin(true);
    }

    const registerHandle = () => {
        // console.log('userName_register', userName_register);
        // console.log('passWord_register', passWord_register);
        // console.log('email_register', email_register);
        // console.log('code_register', code_register);

        let sendData = new FormData();
        sendData.append('userName', userName_register);
        sendData.append('userPass', passWord_register);
        sendData.append('sex', '保密');
        sendData.append('email', email_register);
        sendData.append('code', code_register);
        message.destroy();
        message.loading('注册中...', 0);

        // 注册接口
        postRegister(sendData).then(res => {
            message.destroy();
            message.success(res);
            setIsLogin(true);

        }).catch(error => {
            console.error(error);
            message.destroy();
            message.error(error)
        })
    }


    useEffect(() => {

    }, [])

    return (
        <div className={isLogin ? 'registerBox hiddenBox' : 'registerBox'}>
            <h1 className='DoTitle'>注册</h1>
            <UsernameInput setUserName_register={setUserName_register} />
            <PasswordInput setPassWord_register={setPassWord_register} />
            <EmailInput userName_register={userName_register} setEmail_register={setEmail_register} />
            <CodeInput setCode_register={setCode_register} />
            <Button
                className="registerBtn"
                type="primary"
                onClick={registerHandle}
                size="large"
            >
                开始 ChatSpace
            </Button>
            <div className="goLoginBtn" onClick={moveToLogin}>登录</div>
        </div>
    )
}
