import { PubSub } from "pubsub-js";
import { message } from "antd";

const error = () => {
  message.destroy();
  message.error({
    title: "网络异常",
    content: "请尝试刷新或重新登录！",
    duration: 0,
  });
};

let websocket,
  lockReconnect = false,
  token_timeout = null;

class WsMessage {
  wsType;
  wsContent;

  constructor(wsType, wsContent) {
    this.wsType = wsType;
    this.wsContent = wsContent;
  }

  toJson() {
    return JSON.stringify(this);
  }
}

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

let createWebSocket = (url, token, spaceId) => {
  // console.log('url',url);
  // console.log('token',token);
  // console.log('spaceId',spaceId);

  websocket = new WebSocket(url, [token, spaceId]);

  console.log(`ws start connect in spaceId = ${spaceId}`);

  // console.log('ws start connect')

  websocket.onopen = function () {
    // console.log('ws open')
    lockReconnect = true;
    heartCheck.reset().start();
    // websocket.send(JSON.stringify(sendObj))
  };

  websocket.onerror = function () {
    console.error("ws出错");
    reconnect(url, token, spaceId);
  };

  websocket.onclose = function (e) {
    heartCheck.reset();
    console.log("ws closed: " + e.code + " " + e.reason + " " + e.wasClean);
    if (e.code !== 1005) {
      console.error("ws关闭");
      error();
    }
  };

  websocket.onmessage = function (event) {
    lockReconnect = true;
    //event 为服务端传输的消息，在这里可以处理
    let data = event.data; //把获取到的消息处理成字典，方便后期使用
    PubSub.publish("message", data); //发布接收到的消息 'message' 为发布消息的名称，data 为发布的消息
  };
};

let reconnect = (url, token, spaceId) => {
  if (lockReconnect) return;
  // 没连接上会一直重连，设置延迟避免请求过多
  token_timeout = setTimeout(function () {
    createWebSocket(url, token, spaceId);
    lockReconnect = false;
    token_timeout = null;
  }, 4000);
};
let heartCheck = {
  timeout: 60000, // 心跳时间60秒
  // timeout: 3000,
  timeoutObj: null,

  reset: function () {
    // console.log("reset");
    clearInterval(this.timeoutObj);
    return this;
  },

  start: function () {
    this.timeoutObj = setInterval(function () {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      let userId = sessionStorage.getItem("userId");
      // console.log(new WsMessage('HEART_BEAT', `heart beat of ${userId}`).toJson());
      websocket.send(
        new WsMessage(
          "HEART_BEAT",
          `heart beat of ${userId},date:${new Date().format(
            "yyyy-MM-dd HH:mm:ss"
          )}`
        ).toJson()
      );
    }, this.timeout);
  },
};
// 关闭连接
let closeWebSocket = () => {
    clearTimeout(token_timeout)
    lockReconnect = false;
  websocket && websocket.close();
//   window.
};
export { websocket, createWebSocket, closeWebSocket };
