import React, { useRef, useState, useEffect } from "react";
import { Table, Space, message } from "antd";
import { patchRequset } from "../../../../../request/request";
import { PubSub } from "pubsub-js";

export default function Req_Table(props) {
  let { data } = props;

  // 我的群组表格格式
  const columns = [
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      render: (_, record) => <p>{record.user.userName}</p>,
    },
    {
      title: "申请群组",
      dataIndex: "groupName",
      key: "groupName",
      render: (_, record) => <p>{record.group.groupName}</p>,
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => resolve_apply_handle(record.reqId)}>接受</a>
          <a onClick={() => reject_apply_handle(record.reqId)}>拒绝</a>
        </Space>
      ),
    },
  ];

  const resolve_apply_handle = (reqId) => {
    // console.log(reqId);
    let sendData = new FormData();
    sendData.append("reqId", reqId);
    sendData.append("access", true);
    message.destroy();
    message.loading("正在处理..", 0);

    patchRequset(sendData)
      .then((res) => {
        message.destroy();
        message.success("申请通过成功");
      })
      .catch((err) => {
        message.destroy();
        console.error(err);
        message.error("申请通过失败");
      });
  };

  const reject_apply_handle = (reqId) => {
    let sendData = new FormData();
    sendData.append("reqId", reqId);
    sendData.append("access", false);
    message.destroy();
    message.loading("正在处理..", 0);

    patchRequset(sendData)
      .then((res) => {
        message.destroy();
        message.info("申请拒绝成功");
      })
      .catch((err) => {
        message.destroy();
        console.error(err);
        message.error("申请拒绝失败");
      });
  };

  return (
    <>
      <Table columns={columns} dataSource={data} size="small" pagination={{ defaultPageSize: 6 }} />
    </>
  );
}
