import React, { useState, useEffect } from 'react'
import { Button, message, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom'
import UsernameInput from '../../MyInput/UsernameInput';
import PasswordInput from '../../MyInput/PasswordInput';
import '../../../assets/style/Login/LoginBox/index.scss'
import { postLogin } from '../../../request/request';

const key = 'updatable';

export default function LoginBox(props) {

    const { setIsLogin, isLogin } = props;

    const [userName_login, setUserName_login] = useState();
    const [passWord_login, setPassWord_login] = useState();
    const [isRememberUserName, setIsRememberUserName] = useState(true);

    const navigate = useNavigate();

    // 切换至注册
    const moveToRegister = () => {
        setIsLogin(false);
    }

    //  监听回车按钮
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            loginHandle();
        }
    }

    // 登录按钮
    const loginHandle = () => {
        let sendData = new FormData();
        sendData.append('userName', userName_login);
        sendData.append('userPass', passWord_login);
        message.destroy();
        message.loading('登陆中...', 0);

        // 登录接口
        postLogin(sendData).then(res => {
            sessionStorage.setItem('token', res.token);
            sessionStorage.setItem('userId', res.user.userId);
            sessionStorage.setItem('myAvatar', res.user.avatar);
            sessionStorage.setItem('myUserName', res.user.userName);
            message.destroy();
            // message.success('登录成功');
            // 登录成功之后 若勾选了记住我：
            // 将用户名等信息存入localStorage中
            if (isRememberUserName) {
                // console.log(true);
                localStorage.setItem('ChatSpace_login_remember', userName_login);
            } else {
                // console.log(false);
                localStorage.removeItem('ChatSpace_login_remember');
            }
            message.success({ content: '登陆成功!', key, duration: 0.5 });
            setTimeout(() => {
                navigate('/home')
            }, 1000);

        }).catch(error => {
            console.error(error);
            message.destroy();
            message.error({ content: '登陆失败,用户名或密码错误!', key, duration: 1 })
        })
    }

    // checkbox切换状态
    const onChange_checkBox = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        setIsRememberUserName(e.target.checked)
        // console.log(isRememberUserName);
    };

    // useEffect(() => {
    //     console.log(isRememberUserName);
    //     let localStorage_userName = localStorage.getItem('ChatSpace_login_remember');
    //     if (localStorage_userName !== undefined) {
    //         setIsRememberUserName(true)
    //     }
    // }, [])

    return (
        <div div className={isLogin ? 'loginBox' : 'loginBox hiddenBox'} onKeyDown={handleKeyDown}>
            <h1 className='DoTitle'>登录</h1>
            <UsernameInput isLogin={'true'} setUserName_login={setUserName_login} userName_login={userName_login} />
            <PasswordInput setPassWord_login={setPassWord_login} />
            <Button
                className="loginBtn"
                type="primary"
                onClick={loginHandle}
                size="large"
            >
                登录 ChatSpace
            </Button>
            <div className="loginBox_footer">
                <Checkbox onChange={onChange_checkBox} defaultChecked={isRememberUserName}>记住用户名</Checkbox>
                <div className="goLoginBtn" onClick={moveToRegister}>注册</div>
            </div>
        </div >
    )
}
