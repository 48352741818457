import React, { useRef, useState, useEffect } from "react";
import "../../../../assets/style/Home/Home_comp/OtherT/Group_Modal.scss";
import { Modal, Segmented, Table, Space, Button, Input, message } from "antd";
import Member_Table from "./Member_Table/index";
import Req_Table from "./Req_Table/index";
import Zone_Table from "./Zone_Table/index";
import {
  getGroupList,
  postCreatGroup,
  getGroupMember,
  getRequestList,
  getGroupOwnZone,
} from "../../../../request/request";
import { PubSub } from "pubsub-js";

export default function Group_Modal(props) {
  let { showing, getGroupList_update_function } = props;

  const [is_myGroup_ModalOpen, set_is_myGroup_ModalOpen] = useState(false);
  const [is_group_detail_ModalOpen, set_is_group_detail_ModalOpen] = useState(false);
  const [is_group_create_ModalOpen, set_is_group_create_ModalOpen] = useState(false);
  const [isFirstChange, setisFirstChange] = useState(true);
  const [my_group_arr, set_my_group_arr] = useState([]);
  const [my_group_table_data, set_my_group_table_data] = useState();
  const [showing_group_info, set_showing_group_info] = useState();
  const [Segmented_value, set_Segmented_value] = useState("聊天区域");
  const [my_group_member_arr, set_my_group_member_arr] = useState([]);
  const [my_group_zone_arr, set_my_group_zone_arr] = useState([]);
  const [create_group_loading, set_create_group_loading] = useState(false);

  const create_group_name_ref = useRef();

  useEffect(() => {
    if (!isFirstChange) {
      // 打开我的群组对话框
      open_myGroup_modal();
    } else setisFirstChange(false);
  }, [showing]);

  // 打开我的群组
  const open_myGroup_modal = () => {
    set_is_myGroup_ModalOpen(true);
    get_my_group_info();
  };

  // 获取群组信息
  const get_my_group_info = () => {
    let session_myUserId = sessionStorage.getItem("userId");
    getGroupList(session_myUserId).then((res) => {
      set_my_group_arr(res.groupList);
    });
    getGroupList_update_function();
  };

  const update_groupDetail = (groupId) => {
    getGroupMember(groupId)
      .then((res) => {
        set_my_group_member_arr(res.groupUserList);
      })
      .catch((err) => {
        console.error(err);
        message.error("群组成员加载失败");
      });
    let session_myUserId = sessionStorage.getItem("userId");
    getGroupOwnZone(groupId)
      .then((res) => {
        set_my_group_zone_arr(res.data.zoneList);
      })
      .catch((err) => {
        console.error(err);
        message.error("群组区域信息加载失败");
      });
  };

  // 显示群组信息
  const groupDetail_show = (groupId, groupName) => {
    set_showing_group_info({ groupId, groupName });
    update_groupDetail(groupId);
    set_is_group_detail_ModalOpen(true);
  };

  // 确定创建群组
  const create_group_handle = () => {
    set_create_group_loading(true);
    let groupName = create_group_name_ref.current.input.value;

    let sendData = new FormData();
    sendData.append("groupName", groupName);

    postCreatGroup(sendData)
      .then((res) => {
        // 结束加载动画
        set_create_group_loading(false);
        // 关闭对话框
        set_is_group_create_ModalOpen(false);
        message.success("群组创建成功");
        // 刷新我的群组
        get_my_group_info();
        // console.log(res);
      })
      .catch((err) => {
        set_create_group_loading(false);
        message.error("群组创建失败");
        console.error(err);
      });
  };

  // 分段控制器改变触发函数
  const Segmented_handle = (value) => {
    set_Segmented_value(value);
  };

  // 处理我的群组表格信息
  useEffect(() => {
    // console.log(my_group_arr);
    if (my_group_arr) {
      let temp_data_arr = [];
      for (let index = 0; index < my_group_arr.length; index++) {
        temp_data_arr.push({
          key: index.toString(),
          groupName: my_group_arr[index].groupName,
          groupId: my_group_arr[index].groupId,
          group_member_count: my_group_arr[index].number,
        });
      }
      // console.log(temp_data_arr);
      set_my_group_table_data(temp_data_arr);
    }
  }, [my_group_arr]);

  // 我的群组表格格式
  const columns = [
    {
      title: "群组名称",
      dataIndex: "groupName",
      key: "groupName",
    },
    {
      title: "群组号",
      dataIndex: "groupId",
      key: "groupId",
    },
    {
      title: "群组人数",
      dataIndex: "group_member_count",
      key: "group_member_count",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a onClick={groupDetail_show}>查看</a> */}
          <a
            onClick={() => {
              groupDetail_show(record.groupId, record.groupName);
            }}
          >
            查看
          </a>
          <a
            onClick={() => {
              message.warning("删除功能还未完善，过段时间再试试");
            }}
          >
            删除
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="我的群组"
        open={is_myGroup_ModalOpen}
        onCancel={() => {
          set_is_myGroup_ModalOpen(false);
        }}
        footer={<></>}
        maskClosable={false}
      >
        <Table columns={columns} dataSource={my_group_table_data} />
        <Button
          onClick={() => {
            set_is_group_create_ModalOpen(true);
          }}
          style={{ position: "relative", top: "-50px" }}
        >
          <strong>+</strong>创建群组
        </Button>
      </Modal>
      <Modal
        title="群组详情"
        open={is_group_detail_ModalOpen}
        onCancel={() => {
          set_is_group_detail_ModalOpen(false);
        }}
        footer={<></>}
        maskClosable={false}
        width={600}
      >
        <Space direction="vertical">
          <span>群组名称：{showing_group_info?.groupName}</span>
          <span></span>
        </Space>
        <Segmented block options={["聊天区域", "群组人员"]} onChange={Segmented_handle} />
        {Segmented_value === "群组人员" ? (
          <Member_Table data={my_group_member_arr} />
        ) : (
          <Zone_Table data={my_group_zone_arr} />
        )}
      </Modal>
      <Modal
        title="创建群组"
        open={is_group_create_ModalOpen}
        onCancel={() => {
          set_is_group_create_ModalOpen(false);
        }}
        footer={
          <>
            <Button
              onClick={() => {
                set_is_group_create_ModalOpen(false);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={create_group_handle} loading={create_group_loading}>
              确定创建
            </Button>
          </>
        }
        maskClosable={false}
        width={400}
      >
        <Space>
          <span>群组名称：</span>
          <Input placeholder="请输入要创建的群组名称" ref={create_group_name_ref}></Input>
        </Space>
      </Modal>
    </>
  );
}
