import React from 'react'
import { SafetyOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export default function CodeInput(props) {

    const { setCode_register } = props;

    const onChange = (code) => {
        if (setCode_register)
            setCode_register(code.target.value)
    }

    return (
        <>
            <Input
                placeholder="验证码"
                prefix={<SafetyOutlined className="site-form-item-icon" />}
                size="large"
                onChange={onChange}
            />
        </>
    )
}
