import React from 'react'
import { Table, Space,message } from 'antd';

export default function Member_Table(props) {

    let { data } = props

    // 我的群组表格格式
    const columns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <a onClick={() => { groupDetail_show(record.groupId, record.groupName) }}>查看</a> */}
                    <a onClick={() => { message.warning('删除功能还未完善，过段时间再试试') }}>删除</a>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Table columns={columns} dataSource={data} size="small" pagination={{ defaultPageSize: 6 }}/>
        </>
    )
}
