import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom'
import loginBG from '../assets/img/Chat.svg'
import '../assets/style/Login/index.scss'
import LogoSvg from '../components/LogPage/LogoSvg';
import RegisterBox from '../components/LogPage/RegisterBox';
import LoginBox from '../components/LogPage/LoginBox';


const Login = () => {


    const [isLogin, setIsLogin] = useState(true);

    const leftCardRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            leftCardRef.current.className += ' firstLoading';
        }, 100);
    }, [])

    return (
        <div className="LoginPage">
            <div className="loginLeftCard" ref={leftCardRef}>
                <div className="logoFont">
                    <LogoSvg />
                </div>
                <img src={loginBG} alt="" />
            </div>
            <div className={isLogin ? 'loginRightCard loginNow' : 'loginRightCard registerNow'}>
                {/* <div className="loginRightCard loginNow"> */}
                <RegisterBox setIsLogin={setIsLogin} isLogin={isLogin} />
                <LoginBox setIsLogin={setIsLogin} isLogin={isLogin} />
            </div>
        </div>
    );
};

export default Login;