import axios from 'axios';

const baseURL = 'https://chatspace.iceclean.top/space'
// const baseURL = 'http://localhost:19010/space'

// 获取list聊天列表
export function getFriendList(userId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/friend/list' + '?userId=' + userId, { headers: { 'Token': token } })
            .then(res => {
                if (res.data.msg === '未知异常') {
                    // message.error('请重新登录！')
                } else {
                    resolve(res.data.data);
                }
            }).catch(error => {
                reject(error);
            })
    })
}

// 获取list聊天列表
export function getChatHistory(sendData) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/session/friend/history', { params: sendData, headers: { 'Token': token } })
            .then(res => {
                if (res.msg === '未知异常') {

                }
                resolve(res.data.data.historyList);
            }).catch(error => {
                reject(error);
            })
    })
}

// 登录
export function postLogin(sendData) {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + '/user/login', sendData).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 发送邮箱验证码
export function postCode(sendData) {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + '/user/code', sendData).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data.msg);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 注册
export function postRegister(sendData) {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + '/user/register', sendData).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data.msg);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 获取个人信息
export function getUserInfo(userId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/user/id' + '/' + userId, { headers: { 'Token': token } })
            .then(res => {
                resolve(res.data.data);
            }).catch(error => {
                reject(error);
            })
    })
}

// 修改个人信息(目前只有昵称和性别)
export function patchUserInfo(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.patch(baseURL + '/user/info', sendData, { headers: { 'Token': token } }).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data.msg);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 上传头像
export function postUserAvatar(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.post(baseURL + '/user/avatar', sendData, { headers: { 'Token': token } }).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data.msg);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 申请好友
export function postRequestFriend(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.post(baseURL + '/request/friend', sendData, { headers: { 'Token': token } }).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 获取申请列表
export function getRequestList(userId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/request/list' + '?userId=' + userId, { headers: { 'Token': token } })
            .then(res => {
                if (res.data.status === 0) {
                    resolve(res.data);
                } else {
                    reject(res.data.msg);
                }
            })
    })
}

// 处理申请信息
export function patchRequset(sendData) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.patch(baseURL + '/request/handle', sendData, { headers: { 'Token': token } }).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 删除好友
export function friendDelete(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.delete(baseURL + '/friend', { data: sendData, headers: { 'Token': token } }).then(res => {
            // console.log(res.data.data);
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 获取空间信息
export function getSpaceList() {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/space/list', { headers: { 'Token': token } })
            .then(res => {
                resolve(res.data.data);
            }).catch(error => {
                reject(error);
            })
    })
}

// 获取群组列表
export function getGroupList(userId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/group/creator' + '/' + userId, { headers: { 'Token': token } })
            .then(res => {
                resolve(res.data.data);
            }).catch(error => {
                reject(error);
            })
    })
}

// 创建群组
export function postCreatGroup(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.post(baseURL + '/group', sendData, { headers: { 'Token': token } }).then(res => {
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 创建私人区域
export function postCreatZone(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.post(baseURL + '/zone/private', sendData, { headers: { 'Token': token } }).then(res => {
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 获取群组成员
export function getGroupMember(groupId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/group/list/user' + '?groupId=' + groupId, { headers: { 'Token': token } })
            .then(res => {
                resolve(res.data.data);
            }).catch(error => {
                reject(error);
            })
    })
}

// 获取群组下区域列表
export function getGroupOwnZone(groupId) {
    let token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
        axios.get(baseURL + '/zone/list/zone' + '?groupId=' + groupId, { headers: { 'Token': token } })
            .then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            })
    })
}

// 发送加入群组申请
export function postGroupRequest(sendData) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.post(baseURL + '/request/group', sendData, { headers: { 'Token': token } }).then(res => {
            if (res.data.status === 0) {
                resolve(res.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}

// 首次登录后调用接口
export function getFirstLogin() {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('token');
        axios.get(baseURL + '/user/novice', { headers: { 'Token': token } }).then(res => {
            if (res.data.status === 0) {
                resolve(res.data.data);
            } else {
                reject(res.data.msg);
            }
        }).catch(error => {
            reject(error);
        })
    })
}
