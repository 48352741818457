import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Test from "./pages/Test";
import Index from "./pages/Index";
import { Result } from "antd";
import React, { useState, useEffect } from "react";
import MapProvider from "./Context/context";
// import 'antd/dist/antd.min.css';
function App() {
  const [is_Mobile, set_is_Mobile] = useState(false);
  const [_context_other_user_data_map, set_context_other_user_data_map] = useState(new Map());
  useEffect(() => {
    let ua = navigator.userAgent;
    // console.log(ua.includes('Android'));
    if (ua.includes("Android") || ua.includes("iPhone") || ua.includes("iPad")) {
      set_is_Mobile(true);
    }
  }, []);

  return (
    <div className="App">
      <MapProvider value={{ _context_other_user_data_map, set_context_other_user_data_map }}>
        {is_Mobile ? (
          <>
            <HashRouter>
              <Routes>
                <Route path="*" element={<Index />}></Route>
                <Route
                  path="*"
                  element={
                    <Result status="403" title="无法访问" subTitle="移动端正在加班适配，请使用电脑端访问此网站" />
                  }
                />
              </Routes>
            </HashRouter>
          </>
        ) : (
          <HashRouter>
            <Routes>
              <Route path="/home" element={<Home />}></Route>
              {/* <Route path="/t" element={<Test />}></Route> */}
              <Route path="*" element={<Index />}></Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </HashRouter>
        )}
      </MapProvider>
    </div>
  );
}

export default App;
