import React from 'react'
import { LockOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export default function PasswordInput(props) {

    const { setPassWord_login, setPassWord_register } = props;

    // 输入框修改值同步修改
    const inputChangeHandle = (e) => {
        if (setPassWord_login)
            setPassWord_login(e.target.value);
        if (setPassWord_register)
            setPassWord_register(e.target.value)
    }

    return (
        <>
            <Input.Password
                placeholder="密码"
                prefix={<LockOutlined className="site-form-item-icon" />}
                size="large"
                onChange={inputChangeHandle}
            />
        </>
    )
}
