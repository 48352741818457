import React, { useEffect, useState } from "react";
import { PubSub } from "pubsub-js";
import "../../../assets/style/Home/Home_comp/Radio.scss";
import world_svg from "../../../assets/img/world.svg";
import space_svg from "../../../assets/img/space.svg";
import group_svg from "../../../assets/img/group.svg";

export default function Radio(props) {
  const { radio_ref } = props;

  const [space_radio_arr, set_space_radio_arr] = useState([]);
  const [universe_radio_arr, set_universe_radio_arr] = useState([]);
  const [current_space_radio_arr, set_current_space_radio_arr] = useState([]);
  const [current_universe_radio_arr, set_current_universe_radio_arr] = useState([]);
  const [effect_3000, set_effect_3000] = useState();
  const [effect_7000, set_effect_7000] = useState();
  const [is_no_radio, set_is_no_radio] = useState(true);
  const [current_radio_time, set_current_radio_time] = useState(0);
  const [some_radio_message_stateObj, set_some_radio_message_stateObj] = useState();

  // 订阅消息
  useEffect(() => {
    let some_radio_message_token = PubSub.subscribe("some_radio_message", (_, stateObj) => {
      set_some_radio_message_stateObj(stateObj);
    });

    return () => {
      PubSub.unsubscribe(some_radio_message_token);
    };
  }, []);

  // stateObj_handle
  useEffect(() => {
    if (some_radio_message_stateObj) {
      if (some_radio_message_stateObj.radio_info.sessionType === "SPACE") {
        set_space_radio_arr([...space_radio_arr, some_radio_message_stateObj]);
      } else if (some_radio_message_stateObj.radio_info.sessionType === "UNIVERSE") {
        set_universe_radio_arr([...universe_radio_arr, some_radio_message_stateObj]);
      }
    }
  }, [some_radio_message_stateObj]);

  // 3s检查一次有无新广播
  useEffect(() => {
    if (space_radio_arr?.length > 0) {
      set_is_no_radio(false);
      // 将缓冲数组中的第一个取出
      let temp_space_radio_arr = space_radio_arr;
      let current_space_radio_item = temp_space_radio_arr.shift();
      // 更新缓冲数组
      set_space_radio_arr(temp_space_radio_arr);
      // 添加最新元素进展示数组
      set_current_space_radio_arr([...current_space_radio_arr, current_space_radio_item]);
      set_current_radio_time(new Date().getTime());
    }
    if (universe_radio_arr?.length > 0) {
      set_is_no_radio(false);
      // 将缓冲数组中的第一个取出
      let temp_universe_radio_arr = universe_radio_arr;
      let current_universe_radio_item = temp_universe_radio_arr.shift();
      // 更新缓冲数组
      set_universe_radio_arr(temp_universe_radio_arr);
      // 添加最新元素进展示数组
      set_current_universe_radio_arr([...current_universe_radio_arr, current_universe_radio_item]);
      set_current_radio_time(new Date().getTime());
    }
  }, [effect_3000]);

  // 7s检查一次要不要删数组
  useEffect(() => {
    // 删除第一个元素，防止dom元素过多
    if (current_space_radio_arr?.length > 10) {
      // 将展示数组中的第一个取出
      let temp_current_space_radio_arr = current_space_radio_arr;
      current_space_radio_arr.shift();
      // 更新展示数组
      set_current_space_radio_arr(temp_current_space_radio_arr);
    }
    if (current_universe_radio_arr?.length > 10) {
      // 将展示数组中的第一个取出
      let temp_current_universe_radio_arr = current_universe_radio_arr;
      current_universe_radio_arr.shift();
      // 更新展示数组
      set_current_universe_radio_arr(temp_current_universe_radio_arr);
    }
  }, [effect_7000]);

  // 7s检查一次要不要缩小广播
  useEffect(() => {
    if (new Date().getTime() - current_radio_time > 7000) {
      set_is_no_radio(true);
    }
  }, [effect_7000]);

  // 定时处理space_radio_arr
  useEffect(() => {
    setInterval(() => {
      set_effect_3000(new Date().getTime());
    }, 3000);
    setInterval(() => {
      set_effect_7000(new Date().getTime());
    }, 7000);
  }, []);

  return (
    <>
      <div className="radio_wrapper" ref={radio_ref}>
        <div id="world_radio" className={is_no_radio ? "radio_line no_radio_line" : "radio_line"}>
          <span className="radio_type">
            <img src={world_svg} alt="" />
          </span>
          {current_space_radio_arr.length > 0
            ? current_universe_radio_arr.map((v, i, a) => {
                return (
                  <span className="radio_content_item" key={i + v.radio_info.content}>
                    <span className="sender_userName">管理员</span>:
                    <span className="sender_userName">{v.radio_info.content}</span>
                  </span>
                );
              })
            : ""}
        </div>
        <div id="space_radio" className={is_no_radio ? "radio_line no_radio_line" : "radio_line"}>
          <span className="radio_type">
            <img src={space_svg} alt="" />
          </span>
          <span className="item_box">
            {current_space_radio_arr.length > 0
              ? current_space_radio_arr.map((v, i, a) => {
                  return (
                    <span className="radio_content_item" key={i + v.radio_info.content}>
                      <span className="sender_userName">管理员</span>:
                      <span className="sender_userName">{v.radio_info.content}</span>
                    </span>
                  );
                })
              : ""}
            {/* <span className='radio_content_item'>
                            <span className='sender_userName'>Ouno</span>:
                            <span className='sender_userName'>第一条空间广播</span>
                        </span> */}
          </span>
        </div>
        <div id="group_radio" className={is_no_radio ? "radio_line no_radio_line" : "radio_line"}>
          <span className="radio_type">
            <img src={group_svg} alt="" />
          </span>
          <span className="item_box">
            <span className="radio_content_item">
              <span className="sender_userName">Ouno</span>:<span className="sender_userName">第一条群组广播</span>
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
