import {
  EnvironmentOutlined,
  UserOutlined,
  LayoutFilled,
  LoadingOutlined,
  PlusOutlined,
  MailOutlined,
  SolutionOutlined,
  TeamOutlined,
  BlockOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Layout,
  Avatar,
  message,
  Drawer,
  Upload,
  Select,
  Button,
  Badge,
  Modal,
  Tooltip,
  Space,
  Table,
  Segmented,
  Collapse,
  Input,
  Tour,
  Spin,
} from "antd";
import Map from "../components/HomePage/Map/index";
import Around from "../components/HomePage/Around/index";
import LoadingSvg from "../components/Common/LoadingSvg/index";
import Input_box from "../components//HomePage/OtherT/Input_box/index";
import Chat_invite_box from "../components/HomePage/OtherT/Chat_invite_box/index";
import Chat_history_box from "../components/HomePage/OtherT/Chat_history_box/index";
import Group_Modal from "../components/HomePage/OtherT/Group_Modal/index";
import Radio from "../components/HomePage/Radio/index";
import KeyGroup from "../components/HomePage/KeyGroup/index";
import Req_Table from "../components/HomePage/OtherT/Group_Modal/Req_Table";
import React, { useState, useEffect, useRef, useContext } from "react";
import { PubSub } from "pubsub-js";
import "../assets/style/Home/Home.scss";
import { useNavigate } from "react-router-dom";
import { createWebSocket, closeWebSocket, websocket } from "../utils/websocket";
import {
  getUserInfo,
  patchUserInfo,
  postUserAvatar,
  getRequestList,
  patchRequset,
  getSpaceList,
  getGroupList,
  postCreatZone,
  getFirstLogin,
} from "../request/request";
import * as img_util from "../utils/img_handle";
import * as map_utils from "../utils/map_utils.js";
import { MyContext } from "../Context/context";
// import '../utils/func_test.js'

const { Header } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const ws_url = `wss://chatspace.iceclean.top/space/ws/chat`; //服务端连接的url
// const ws_url = `ws://localhost:19019/space/ws/chat`;//服务端连接的url

// 初始化地图大小
let init_area_size_global = 0;

// 节流参数
let detail_showing_throttle = new Date().getTime();

// 图片上传使用函数 ==============
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("只允许上传JPG/PNG文件");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("文件大小不可超过2MB");
  }

  return isJpgOrPng && isLt2M;
};
// 图片上传使用函数 ==============

const Home = () => {
  // 引入全局变量
  const mycontext = useContext(MyContext);
  // console.log(mycontext._context_other_user_data_map);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [myUserName, setMyUserName] = useState("默认用户名");
  const [myAvatar, setMyAvatar] = useState("");
  // logo旁边的加载动画显示条件
  const [switch_loading, set_switch_loading] = useState(false);
  // detail是否展示
  const [switch_detail, set_switch_detail] = useState(false);
  // rightCont内部DOM节点
  const [rightCont_text, set_rightCont_text] = useState();
  // detailCont内部DOM节点
  const [detailCont, set_detailCont] = useState();
  // controlBox显示内容类别
  const [controlBox_info_type, set_controlBox_info_type] = useState("position");
  // 个人信息
  const [myUser_data, set_myUser_data] = useState({
    x: 0,
    y: 0,
    vision: 0,
    exist: 0,
  });
  // 图片上传loading
  const [img_upload_loading, set_img_upload_loading] = useState(false);
  // 图片URL
  const [imageUrl, setImageUrl] = useState();
  // 图片文件
  const [imageFile, setImageFile] = useState();
  // 个人信息设置默认昵称
  const [setting_userName, set_setting_userName] = useState();
  // 个人信息设置默认性别
  const [setting_gender, set_setting_gender] = useState("保密");
  // 邀请信息抽屉
  const [invite_drawer_open, set_invite_drawer_open] = useState(false);
  // 好友添加信息抽屉
  const [friend_add_drawer_open, set_friend_add_drawer_open] = useState(false);
  // 聊天状态
  const [is_chatting, set_is_chatting] = useState(false);
  // 设置聊天区域Modal显示
  const [set_area_isModalOpen, set_set_area_isModalOpen] = useState(false);
  // 设置聊天区域Modal按钮loading
  const [area_modal_loading, set_area_modal_loading] = useState(false);
  // 设置聊天区域Modal显示
  const [edit_area_isModalOpen, set_edit_area_isModalOpen] = useState(false);
  // 受邀信息列表数据
  const [invite_arr, set_invite_arr] = useState([]);
  // 添加好友列表数据
  const [request_friend_add_arr, set_request_friend_add_arr] = useState([]);
  // 申请好友列表数据
  const [request_to_friend_add_arr, set_request_to_friend_add_arr] = useState([]);
  // 已发送群组申请列表数据
  const [request_group_add_arr, set_request_group_add_arr] = useState([]);
  // 已发送群组申请列表数据
  const [request_group_apply_arr, set_request_group_apply_arr] = useState([]);
  // 临时聊天id
  const [sessionId, set_sessionId] = useState();
  // 初始化缓冲
  const [buffer_for_init, set_buffer_for_init] = useState(false);
  // 邀请信息未读
  const [invite_info_toRead, set_invite_info_toRead] = useState(false);
  // 好友申请信息未读
  const [add_friend_toRead, set_add_friend_toRead] = useState(false);
  // 选择进入空间spaceId
  const [select_space_modal_show, set_select_space_modal_show] = useState(false);
  // space空间数据
  const [select_space_data, set_select_space_data] = useState(null);
  // space空间数据加载flag
  const [select_space_data_loading, set_select_space_data_loading] = useState(false);
  // 选择进入空间spaceId
  const [select_space_value, set_select_space_value] = useState(-1);
  // 空间名称
  const [select_space_value_name, set_select_space_value_name] = useState("起源空间");
  // 是否显示个人信息修改
  const [is_show_confirm_user_info, set_is_show_confirm_user_info] = useState(false);
  // 是否显示个人信息修改
  const [confirm_user_info_btn_loading, set_confirm_user_info_btn_loading] = useState(false);
  // 好友申请信息未读
  const [is_show_group_modal, set_is_show_group_modal] = useState(false);
  // 我的群组
  const [my_group_arr, set_my_group_arr] = useState([]);
  // 确定创建区域所属群组
  const [my_zone_in_group, set_my_zone_in_group] = useState();
  // 确定创建区域建立方向
  const [my_zone_create_orient, set_my_zone_create_orient] = useState();
  // 受邀信息分段选择器
  const [invited_info_Segmented, set_invited_info_Segmented] = useState("聊天申请");
  // 开启教程
  const [tour_open, set_tour_open] = useState(false);
  // 是否第一次进入该网站
  const [is_first_enter, set_is_first_enter] = useState(false);
  // 实时显示延时
  const [current_delay, set_current_delay] = useState(0);

  // pubsub_stateObj
  const [keyboard_change_myUser_data_stateObj, set_keyboard_change_myUser_data_stateObj] = useState();
  const [inviting_user_info_stateObj, set_inviting_user_info_stateObj] = useState();
  const [my_message_stateObj, set_my_message_stateObj] = useState();
  const [end_chat_stateObj, set_end_chat_stateObj] = useState();

  // ref
  const detail_dom_ref = useRef();
  const control_dom_ref = useRef();
  const control_left_contan_dom_ref = useRef();
  const control_right_contan_dom_ref = useRef();
  const position_x_input = useRef();
  const position_y_input = useRef();
  const vision_input = useRef();
  const exist_input = useRef();
  const setting_userName_input = useRef();
  const set_area_name = useRef();
  const area_width_x_ref = useRef();
  const area_width_y_ref = useRef();
  const switch_btn_ref = useRef();
  const self_btn_ref = useRef();
  const radio_ref = useRef();
  const around_ref = useRef();
  const keygroup_ref = useRef();
  const myUser_data_ref = useRef(myUser_data);

  /*   ------------------------------ ------------------------  个人信息管理   ------------------------- -------------------------------------    */

  // 更新用户信息
  const getUserInfo_update = () => {
    // 获取个人信息
    let session_myUserId = sessionStorage.getItem("userId");
    // 如果没有userId在essionStorage中
    if (!session_myUserId) {
      message.error("未检测到用户登录，现返回登录页进行登录！");
      navigate("/login", { replace: true });
    }
    getUserInfo(session_myUserId)
      .then((res) => {
        set_setting_userName(res.nickName);
        setMyAvatar(res.avatar);
        if (res.avatar.includes("default")) set_is_first_enter(true);
        setMyUserName(res.nickName);
        set_setting_gender(res.sex);
        PubSub.publish("my_media_info", {
          avatar: res.avatar,
          nickName: res.nickName,
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("身份过期，请重新登陆！");
      });
  };
  const getGroupList_update = () => {
    let session_myUserId = sessionStorage.getItem("userId");
    getGroupList(session_myUserId)
      .then((res) => {
        let temp_arr = [];
        for (let index = 0; index < res.groupList.length; index++) {
          temp_arr.push({
            value: res.groupList[index].groupId,
            label: res.groupList[index].groupName,
          });
        }
        set_my_group_arr(temp_arr);
      })
      .catch((err) => {
        console.error(err);
        message.error("群组获取失败");
      });
  };

  // 图片上传函数
  const img_handleChange = (info) => {
    // 因为上传的头像，会存入一个数组，但是我们只使用最新上传的图片作为头像
    // 所以每次都将最后一个元素作为头像
    const target_file = info.fileList[info.fileList.length - 1].originFileObj;
    // Get this url from response in real world.
    getBase64(target_file, (url) => {
      set_img_upload_loading(false);
      setImageUrl(url);
    });

    setImageFile(target_file);
  };

  // 性别修改函数
  const gender_handleChange = (value) => {
    set_setting_gender(value);
  };

  // 根据userId获取个人信息
  const get_userInfo_by_userId = (userId) => {
    return mycontext._context_other_user_data_map.get(userId);
  };

  /*   ------------------------------ -----------------------  界面要素控制   ------------------------- -------------------------------------    */

  // 是否展开导航栏详情信息
  useEffect(() => {
    if (switch_detail) {
      detail_dom_ref.current.classList.add("detail_showing");
      PubSub.publish("detail_showing", { isShowing: switch_detail });
    } else {
      detail_dom_ref.current.classList.remove("detail_showing");
      PubSub.publish("detail_showing", { isShowing: switch_detail });
    }
  }, [switch_detail]);

  // 展示出设置位置的详细信息
  const show_position_setting = () => {
    if (is_chatting) {
      message.destroy();
      message.warning("正在聊天，无法调整位置与信息");
    } else {
      // 节流
      let now = new Date().getTime();
      if (now - detail_showing_throttle > 1400) {
        if (switch_detail) {
          set_switch_detail(false);
        } else set_switch_detail(true);
        detail_showing_throttle = new Date().getTime();
      }
      if (controlBox_info_type === "position") {
        set_detailCont(controlBox_info_detail_position);
      } else if (controlBox_info_type === "ev_info") {
        set_detailCont(controlBox_info_detail_ev_info);
      }
    }
  };

  // 切换导航栏信息
  const switch_controlBox_info = () => {
    if (controlBox_info_type === "position") {
      control_left_contan_dom_ref.current.innerHTML = "个人参数";
      set_controlBox_info_type("ev_info");
      set_rightCont_text(return_controlBox_info_rightCont_ev_info(myUser_data.vision, myUser_data.exist));
      set_detailCont(controlBox_info_detail_ev_info);
      // set_switch_loading(false)
    } else if (controlBox_info_type === "ev_info") {
      control_left_contan_dom_ref.current.innerHTML = "您的位置";
      set_controlBox_info_type("position");
      set_rightCont_text(return_controlBox_info_rightCont_position(myUser_data.x, myUser_data.y));
      set_detailCont(controlBox_info_detail_position);
      // set_switch_loading(true)
    }
  };

  // 显示邀请信息抽屉
  const show_invite_info = () => {
    set_invite_info_toRead(false);
    set_invite_drawer_open(true);
    update_request_data();
  };

  // 关闭邀请信息抽屉
  const close_invite_info = () => {
    set_invite_drawer_open(false);
  };

  // 显示好友添加抽屉
  const show_friend_add_info = () => {
    set_add_friend_toRead(false);
    set_friend_add_drawer_open(true);
    update_request_data();
  };

  // 更新请求数据
  const update_request_data = () => {
    let session_myUserId = sessionStorage.getItem("userId");
    getRequestList(session_myUserId)
      .then((res) => {
        if (res.data.requestFriend) {
          let temp_need_show_add_arr = [];
          for (let index = 0; index < res.data.requestFriend.length; index++) {
            if (res.data.requestFriend[index].reqStatus === 0 || res.data.requestFriend[index].reqStatus === 1)
              temp_need_show_add_arr.push(res.data.requestFriend[index]);
          }
          set_request_friend_add_arr(temp_need_show_add_arr);
        }
        if (res.data.friendRequest) {
          set_request_to_friend_add_arr(res.data.friendRequest);
        }
        if (res.data.requestGroup) {
          set_request_group_add_arr(res.data.requestGroup);
        }
        if (res.data.groupRequest) {
          let temp_group_apply_arr = [];
          for (let index = 0; index < res.data.groupRequest?.length; index++) {
            if (res.data.groupRequest[index].reqStatus === 0 || res.data.groupRequest[index].reqStatus === 1)
              temp_group_apply_arr.push(res.data.groupRequest[index]);
          }
          set_request_group_apply_arr(temp_group_apply_arr);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 关闭好友添加抽屉
  const close_friend_add_info = () => {
    set_friend_add_drawer_open(false);
  };

  // 退出当前空间
  const out_of_cur_space = () => {
    PubSub.publish("chat_status", { chatting: false, targetType: "FRIEND" });
    PubSub.publish("chat_status", { chatting: false, targetType: "TEMP" });
    PubSub.publish("chat_status", {
      chatting: false,
      targetType: "ZONE",
      is_out_space: true,
    });
    PubSub.publish("out_of_current_space", {});
    closeWebSocket();
  };

  // 弹出选择空间
  useEffect(() => {
    set_select_space_data_loading(true);
    if (select_space_modal_show) {
      getSpaceList()
        .then((res) => {
          set_select_space_data(res);
          set_select_space_data_loading(false);
        })
        .catch((err) => {
          console.error(err);
          message.error("空间信息获取出错，请尝试刷新！");
          set_select_space_data_loading(false);
        });
    }
  }, [select_space_modal_show]);

  /*   ------------------------------ -----------------------  地图个人控制   ------------------------- -------------------------------------    */

  // 修改map中个人信息
  let transfer_myUser_info = (target) => {
    for (const key in target) {
      target[key] = parseInt(target[key]);
    }
    if (target.vision > 30 || target.exist > 20) {
      message.destroy();
      message.warning("设置个人参数不合理！");
    } else if (target.x >= init_area_size_global || target.y >= init_area_size_global) {
      message.destroy();
      message.warning(`所设置位置超出地图范围，当前地图大小为${init_area_size_global}X${init_area_size_global}`);
    } else {
      websocket &&
        websocket.send(
          JSON.stringify({
            wsType: "SITE_UPDATE", // 固定写法，标识发送消息
            wsContent: {
              x: target.x, // 所处 x 坐标
              y: target.y, // 所处 y 坐标
              vision: target.vision, // 视野大小
              exist: target.exist, // 存在感大小
            },
          })
        );
      PubSub.publish("init_myUser", target);
    }
  };

  // 传送触发函数
  const transfer_handle = () => {
    let target = {
      x: parseInt(position_x_input.current.value),
      y: parseInt(position_y_input.current.value),
      vision: myUser_data_ref.current.vision,
      exist: myUser_data_ref.current.exist,
    };
    if (!target.x || !target.y) {
      message.destroy();
      message.warning("请填写正确的坐标值");
    } else transfer_myUser_info(target);
  };

  // 修改ev函数
  const change_ev_handle = () => {
    let target = {
      x: myUser_data_ref.current.x,
      y: myUser_data_ref.current.y,
      vision: parseInt(vision_input.current.value),
      exist: parseInt(exist_input.current.value),
    };
    if (!target.vision || !target.exist) {
      message.destroy();
      message.warning("请填写正确的视野与存在感");
    } else transfer_myUser_info(target);
  };

  /*   ------------------------------ -----------------------  载入操作   ------------------------- -------------------------------------    */

  const load_websocket = () => {
    let token = sessionStorage.getItem("token");
    createWebSocket(ws_url, token, select_space_value);
  };

  // 判断是否选择空间
  useEffect(() => {
    if (select_space_value !== -1) {
      set_switch_loading(false);
      // 加载websocket
      load_websocket();
    }
  }, [select_space_value]);

  // 连接websocket
  useEffect(() => {
    // 更新用户信息
    getUserInfo_update();
    // 更新数组信息
    getGroupList_update();
    // 打开space选择modal
    set_select_space_modal_show(true);
    let messageSocket = PubSub.subscribe("message", getMsg);
    return () => {
      //在组件卸载的时候，关闭连接
      closeWebSocket();
      PubSub.unsubscribe(messageSocket);
    };
  }, []);

  useEffect(() => {
    // 进页面先显示加载动画
    control_dom_ref.current.classList.add("isLoading");

    if (switch_loading) {
      control_dom_ref.current.classList.remove("isLoading");
      setTimeout(() => {
        // 1.5+0.6 秒后加入文字信息，防止宽度变化使得动画变形
        if (control_left_contan_dom_ref.current) control_left_contan_dom_ref.current.innerHTML = "您的位置";
      }, 600);
      setTimeout(() => {
        if (is_first_enter) set_tour_open(true);
      }, 1200);
    } else {
      if (control_left_contan_dom_ref.current) control_left_contan_dom_ref.current.innerHTML = null;
      set_rightCont_text();
    }
  }, [switch_loading]);

  // 监听pubsub
  useEffect(() => {
    let chat_status_token = PubSub.subscribe("chat_status", (_, stateObj) => {
      set_is_chatting(stateObj.chatting);
    });

    let change_myUser_data = PubSub.subscribe("keyboard_change_myUser_data", (_, stateObj) => {
      set_keyboard_change_myUser_data_stateObj(stateObj);
    });

    return () => {
      PubSub.unsubscribe(chat_status_token);
      PubSub.unsubscribe(change_myUser_data);
    };
  }, []);

  // 键盘移动后传输回来的信息处理
  useEffect(() => {
    if (keyboard_change_myUser_data_stateObj === undefined) {
    } else {
      // let temp_myUser_data = myUser_data;
      let temp_myUser_data = Object.assign({}, myUser_data);
      temp_myUser_data.x = keyboard_change_myUser_data_stateObj.x;
      temp_myUser_data.y = keyboard_change_myUser_data_stateObj.y;
      temp_myUser_data.vision = keyboard_change_myUser_data_stateObj.vision;
      temp_myUser_data.exist = keyboard_change_myUser_data_stateObj.exist;
      set_myUser_data(temp_myUser_data);
      myUser_data_ref.current = temp_myUser_data;
    }
  }, [keyboard_change_myUser_data_stateObj]);

  /*   ------------------------------ -----------------------  websocket处理   ------------------------- -------------------------------------    */

  // websocket函数路由
  const getMsg = (topic, myMessage) => {
    // console.log('ws获取:', JSON.parse(myMessage));
    let MsgObj = JSON.parse(myMessage);
    if (MsgObj.timestamp) {
      // console.log(`客户端时间戳${Date.now()},服务端时间戳${MsgObj.timestamp}`);
      set_current_delay(Date.now() - MsgObj.timestamp);
    }
    if (MsgObj.wsType === "SEND_MESSAGE") {
      if (
        MsgObj.wsContent.sessionType !== "SPACE" &&
        MsgObj.wsContent.sessionType !== "UNIVERSE" &&
        MsgObj.wsContent.sessionType !== "GROUP"
      )
        get_send_msg(MsgObj.wsContent);
      else get_radio_msg(MsgObj.wsContent);
    } else if (MsgObj.wsType === "SPACE_INIT") {
      setTimeout(() => {
        set_switch_loading(true);
      }, 1000);
      // 等加载动画进行一段时间后完成初始化，避免显得加载动画卡顿
      setTimeout(() => {
        init_map_info(MsgObj.wsContent.space, MsgObj.wsContent.userSite, MsgObj.wsContent.zoneList);
      }, 1400);
    } else if (MsgObj.wsType === "VISION_UPDATE") {
      init_other_user(MsgObj.wsContent);
    } else if (MsgObj.wsType === "SITE_CHANGE") {
      change_other_user("change", MsgObj.wsContent);
    } else if (MsgObj.wsType === "SITE_ADD") {
      // change_other_user("add", MsgObj.wsContent);
    } else if (MsgObj.wsType === "SITE_REMOVE") {
      change_other_user("remove", MsgObj.wsContent);
    } else if (MsgObj.wsType === "SESSION_REQUEST") {
      if (!MsgObj.wsContent.group) {
        set_add_friend_toRead(true);
      }
    } else if (MsgObj.wsType === "CHAT_REQUEST_FIN_ACK") {
      get_ending_ask_msg(MsgObj.wsContent);
    } else if (MsgObj.wsType === "CHAT_REQUEST_SYN_ACK") {
      get_inviting_ask_msg(MsgObj.wsContent);
    } else if (MsgObj.wsType === "CHAT_REQUEST_SYN") {
      get_inviting_msg(MsgObj.wsContent);
    } else if (MsgObj.wsType === "TOKEN_EXPIRED") {
      Modal.error({
        title: "注意",
        content: "登录信息过期，请重新登录！",
        onOk() {
          logoutHandle();
        },
      });
    } else if (MsgObj.wsType === "ZONE_IN") {
      PubSub.publish("zone_in", { zone_info: MsgObj.wsContent });
    } else if (MsgObj.wsType === "ZONE_IN_DENY") {
      PubSub.publish("zone_in_deny", { zone_info: MsgObj.wsContent });
    } else if (MsgObj.wsType === "ZONE_OUT") {
      PubSub.publish("zone_out", { zone_info: MsgObj.wsContent });
    } else if (MsgObj.wsType === "ZONE_INIT") {
      PubSub.publish("zone_init", { zone_init_info: MsgObj.wsContent });
    } else if (MsgObj.wsType === "NEW_ZONE") {
      PubSub.publish("new_zone", { new_zone_info: MsgObj.wsContent });
    } else if (MsgObj.wsType === "USER_FORCED_OFFLINE") {
      Modal.error({
        title: "注意",
        content: MsgObj.wsContent,
        onOk() {
          logoutHandle();
        },
      });
    } else if (MsgObj.wsType === "CHAT_REQUEST_UNREACHABLE") {
      inviteing_unreachable();
    }
  };

  // 发送websocket--更新个人信息
  useEffect(() => {
    if (switch_loading) {
      if (controlBox_info_type === "position")
        set_rightCont_text(return_controlBox_info_rightCont_position(myUser_data.x, myUser_data.y));
      else if (controlBox_info_type === "ev_info")
        set_rightCont_text(return_controlBox_info_rightCont_ev_info(myUser_data.vision, myUser_data.exist));
    }
    if (buffer_for_init) {
      // if (!websocket) message.error('登陆超时，请刷新或重新登陆')
      websocket &&
        websocket.send(
          JSON.stringify({
            wsType: "SITE_UPDATE", // 固定写法，标识发送消息
            wsContent: {
              x: myUser_data.x, // 所处 x 坐标
              y: myUser_data.y, // 所处 y 坐标
              vision: myUser_data.vision, // 视野大小
              exist: myUser_data.exist, // 存在感大小
            },
          })
        );
    } else set_buffer_for_init(true);
  }, [myUser_data]);

  // 初始化地图信息
  const init_map_info = (map_info, my_user_info, zone_info) => {
    // 将信息发送给Map组件进行数据赋值
    PubSub.publish("init_map", { map_info, zone_info });
    PubSub.publish("init_myUser_first", { my_user_info });
    set_myUser_data({
      x: my_user_info.x,
      y: my_user_info.y,
      vision: my_user_info.vision,
      exist: my_user_info.exist,
    });
    myUser_data_ref.current = {
      x: my_user_info.x,
      y: my_user_info.y,
      vision: my_user_info.vision,
      exist: my_user_info.exist,
    };
    // 确定地图大小
    init_area_size_global = map_info.sizeX;
    // 刷新导航栏
    set_rightCont_text(return_controlBox_info_rightCont_position(my_user_info.x, my_user_info.y));
  };

  // 视野范围内用户变化
  const change_other_user = (typeStr, other_user_info_arr) => {
    PubSub.publish("change_other_user", { typeStr, other_user_info_arr });
  };

  // 视野范围内用户更改
  const init_other_user = (other_user_info_arr) => {
    // PubSub.publish("init_other_user", { other_user_info_arr });
    console.log("error: move way has changed");
  };

  // 聊天邀请未送达
  const inviteing_unreachable = () => {
    PubSub.publish("chat_status", {
      chatting: false,
      targetType: "TEMP",
    });
    setTimeout(() => {
      PubSub.publish("get_inviting_ask_msg", { unreachable: true });
    }, 0);
  };

  // 收到聊天邀请确认
  const get_inviting_ask_msg = (inviting_ask_msg) => {
    // 发送到chat_invite_box来控制
    PubSub.publish("get_inviting_ask_msg", { inviting_ask_msg });
    if (inviting_ask_msg.access) {
      PubSub.publish("chat_status", {
        chatting: true,
        targetType: inviting_ask_msg.sessionId > 0 ? "FRIEND" : "TEMP",
      });
      set_sessionId(inviting_ask_msg.sessionId);
    } else {
      PubSub.publish("chat_status", {
        chatting: false,
        targetType: inviting_ask_msg.sessionId > 0 ? "FRIEND" : "TEMP",
      });
    }
  };

  // 收到聊天结束确认
  const get_ending_ask_msg = (ending_info) => {
    PubSub.publish("chat_status", {
      chatting: false,
      targetType: ending_info.sessionId > 0 ? "FRIEND" : "TEMP",
    });
  };

  // 收到聊天信息
  const get_send_msg = (send_msg) => {
    PubSub.publish("some_user_message", {
      my_message_cont: send_msg.content,
      senderId: send_msg.sender.userId,
    });
  };

  // 收到广播消息
  const get_radio_msg = (send_msg) => {
    PubSub.publish("some_radio_message", {
      radio_info: send_msg,
    });
  };

  // 收到聊天邀请
  const get_inviting_msg = (inviting_user_info) => {
    set_invite_info_toRead(true);
    set_inviting_user_info_stateObj(inviting_user_info);
  };

  // 邀请请求接收更新
  useEffect(() => {
    if (!inviting_user_info_stateObj) {
    } else {
      let temp_arr = [...invite_arr];
      temp_arr = [...temp_arr, inviting_user_info_stateObj];
      // console.log(temp_arr);
      set_invite_arr(temp_arr);
    }
  }, [inviting_user_info_stateObj]);

  // pubsub订阅和handle
  useEffect(() => {
    let send_chat_request_token = PubSub.subscribe("send_chat_request", (_, stateObj) => {
      websocket &&
        websocket.send(
          JSON.stringify({
            wsType: "CHAT_REQUEST_SYN",
            wsContent: {
              type: stateObj.is_friend_chat ? "FRIEND" : "TEMP",
              targetId: stateObj.targetId,
            },
          })
        );
    });

    let my_message_token = PubSub.subscribe("my_message", (_, stateObj) => {
      set_my_message_stateObj(stateObj);
    });

    let end_chat_token = PubSub.subscribe("end_chat", (_, stateObj) => {
      set_end_chat_stateObj(stateObj);
    });

    // 退出区域
    let exit_zone_token = PubSub.subscribe("exit_zone", (_, stateObj) => {
      if (stateObj.exit) {
        websocket &&
          websocket.send(
            JSON.stringify({
              wsType: "EXIT_ZONE", // 固定写法，标识发送消息
              wsContent: {},
            })
          );
      }
    });

    // 进入区域
    let zone_in_ack_token = PubSub.subscribe("zone_in_ack", (_, stateObj) => {
      websocket &&
        websocket.send(
          JSON.stringify({
            wsType: "ZONE_IN_ACK", // 固定写法，标识发送消息
            wsContent: {
              access: stateObj.state, // 消息类型
            },
          })
        );
    });

    return () => {
      PubSub.unsubscribe(send_chat_request_token);
      PubSub.unsubscribe(my_message_token);
      PubSub.unsubscribe(end_chat_token);
      PubSub.unsubscribe(exit_zone_token);
      PubSub.unsubscribe(zone_in_ack_token);
    };
  }, []);

  // 聊天信息发送websocket
  useEffect(() => {
    if (!my_message_stateObj) {
    } else {
      if (my_message_stateObj.targetType === "ZONE") {
        websocket &&
          websocket.send(
            JSON.stringify({
              wsType: "SEND_MESSAGE",
              wsContent: {
                senderId: parseInt(sessionStorage.getItem("userId")),
                targetId: my_message_stateObj.zone_sessionId,
                content: my_message_stateObj.my_message_cont, // 消息主体内容
                sessionType: "ZONE",
                messageType: "TEXT",
              },
            })
          );
      } else {
        websocket &&
          websocket.send(
            JSON.stringify({
              wsType: "SEND_MESSAGE",
              wsContent: {
                senderId: parseInt(sessionStorage.getItem("userId")),
                sessionType: my_message_stateObj.targetType,
                targetId: sessionId,
                messageType: "TEXT",
                content: my_message_stateObj.my_message_cont, // 消息主体内容
              },
            })
          );
      }
    }
  }, [my_message_stateObj]);

  // 结束聊天websocket
  useEffect(() => {
    if (end_chat_stateObj) {
      if (end_chat_stateObj.end) {
        websocket &&
          websocket.send(
            JSON.stringify({
              wsType: "CHAT_REQUEST_FIN",
              wsContent: {
                targetId: sessionId,
              },
            })
          );
      }
    }
  }, [end_chat_stateObj]);

  /* 
    =============================================================WEBSOCKET处理====================================================
  */

  // 退出登录函数
  const logoutHandle = () => {
    closeWebSocket();
    navigate("/login", { replace: true });
    message.destroy();
  };

  // 接受好友申请
  const accept_friend_add = (accept_friend_add_reqId) => {
    messageApi.open({
      type: "loading",
      content: "正在接受好友申请",
      duration: 0,
    });
    let sendFormData = new FormData();
    sendFormData.append("reqId", accept_friend_add_reqId);
    sendFormData.append("access", true);
    patchRequset(sendFormData)
      .then((res) => {
        // console.log(res);
        if (res.msg === "ok") {
          messageApi.destroy();
          message.destroy();
          message.success("添加成功");
          delete_friend_add_info(accept_friend_add_reqId);
          PubSub.publish("refresh_friend", { refresh: true });
        }
      })
      .catch((err) => {
        message.destroy();
        message.error(err);
      });
  };

  // 拒绝好友申请
  const reject_friend_add = (reject_friend_add_reqId) => {
    let sendFormData = new FormData();
    sendFormData.append("reqId", reject_friend_add_reqId);
    sendFormData.append("access", false);
    patchRequset(sendFormData)
      .then((res) => {
        // console.log(res);
        if (res.msg === "ok") {
          delete_friend_add_info(reject_friend_add_reqId);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // 将好友申请信息从数据arr里删除
  const delete_friend_add_info = (friend_add_reqId) => {
    let temp_arr = [...request_friend_add_arr];
    for (let index = 0; index < temp_arr.length; index++) {
      if (temp_arr[index].reqId === friend_add_reqId) {
        temp_arr.splice(index, 1);
        break;
      }
    }
    // console.log('删除后的数组', temp_arr);
    set_request_friend_add_arr(temp_arr);
  };

  // 接受聊天邀请
  const accept_invite = (accept_invite_userId, targetTypeString) => {
    PubSub.publish("chat_status", {
      chatting: true,
      targetType: targetTypeString,
    });
    set_invite_drawer_open(false);
    websocket &&
      websocket.send(
        JSON.stringify({
          wsType: "CHAT_REQUEST_SYN_ACK", // 固定写法，标识发送消息
          wsContent: {
            type: targetTypeString,
            targetId: accept_invite_userId,
            access: true, // 同意建立聊天关系
          },
        })
      );
    delete_invite_info(accept_invite_userId);
  };

  // 拒绝聊天邀请
  const reject_invite = (reject_invite_userId, targetTypeString) => {
    PubSub.publish("chat_status", {
      chatting: false,
      targetType: targetTypeString,
    });
    set_invite_drawer_open(false);
    websocket &&
      websocket.send(
        JSON.stringify({
          wsType: "CHAT_REQUEST_SYN_ACK", // 固定写法，标识发送消息
          wsContent: {
            type: targetTypeString,
            targetId: reject_invite_userId,
            access: false, // 拒绝建立聊天关系
          },
        })
      );
    delete_invite_info(reject_invite_userId);
  };

  // 将邀请信息从数据arr里删除
  const delete_invite_info = (invite_userId) => {
    let temp_arr = [...invite_arr];
    for (let index = 0; index < temp_arr.length; index++) {
      if (temp_arr[index].site.userId === invite_userId) {
        temp_arr.splice(index, 1);
        break;
      }
    }
    set_invite_arr(temp_arr);
  };

  // 显示设置聊天区域
  const show_set_area_Modal = () => {
    set_set_area_isModalOpen(true);
    // message.destroy();
    // message.warning('自定义区域功能暂未开发完成！')
  };

  // 确定建立聊天区域
  const set_area_handleOk = (this_my_group_arr, this_my_zone_in_group, this_build_area_orient) => {
    set_area_modal_loading(true);
    let map_main = document.querySelector(".map_main");

    let set_zone_name = set_area_name.current.input.value;
    let area_width_x = area_width_x_ref.current.input.value;
    let area_width_y = area_width_y_ref.current.input.value;

    let send_data = new FormData();
    if (this_my_zone_in_group) {
      send_data.append("groupId", this_my_zone_in_group);
    } else {
      send_data.append("groupId", this_my_group_arr[0].value);
    }
    send_data.append("zoneName", set_zone_name);
    send_data.append("spaceId", select_space_value);
    send_data.append("sizeX", area_width_x);
    send_data.append("sizeY", area_width_y);
    if (set_zone_name === "" || area_width_x === "" || area_width_y === "") {
      message.destroy();
      message.warning("内容不能为空");
      set_area_modal_loading(false);
    } else {
      postCreatZone(send_data)
        .then((res) => {
          // console.log(res);
          message.success("区域创建成功");
          set_set_area_isModalOpen(false);
          set_area_modal_loading(false);
        })
        .catch((err) => {
          console.error(err);
          message.error("区域创建失败");
          set_area_modal_loading(false);
        });
    }
  };

  const set_area_handleCancel = () => {
    set_set_area_isModalOpen(false);
  };

  // 显示设置聊天区域
  const show_group_Modal = () => {
    set_is_show_group_modal(!is_show_group_modal);
  };

  const edit_area_handleOk = () => {
    set_edit_area_isModalOpen(false);
  };

  const edit_area_handleCancel = () => {
    set_edit_area_isModalOpen(false);
  };

  const my_group_arr_select_handleChange = (e, v) => {
    set_my_zone_in_group(e);
  };

  const build_area_orient_select_handleChange = (e, v) => {
    set_my_zone_create_orient(e);
  };
  /*   ------------------------------ ---------------------  配置信息   ------------------------- -------------------------------------    */
  const friend_add_table_columns = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      render: (_, record) => <img src={img_util.img_translate(record?.user?.avatar)} className="table_avatar"></img>,
    },
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      render: (_, record) => <span>{record?.user?.userName}</span>,
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => accept_friend_add(record.reqId)}>接受</a>
          <a onClick={() => reject_friend_add(record.reqId)}>拒绝</a>
        </Space>
      ),
    },
  ];

  const invite_chat_table_columns = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      render: (_, record) => (
        <img
          src={img_util.img_translate(get_userInfo_by_userId(record?.site?.userId)?.user?.avatar)}
          className="table_avatar"
        ></img>
      ),
    },
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      render: (_, record) => <span>{get_userInfo_by_userId(record?.site?.userId)?.user?.userName}</span>,
    },
    {
      title: "类型",
      dataIndex: "chat_type",
      key: "chat_type",
      render: (_, record) => <span>{record.type === "TEMP" ? "临时聊天" : "好友私聊"}</span>,
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => accept_invite(record?.site?.userId, record.type)}>接受</a>
          <a onClick={() => reject_invite(record?.site?.userId, record.type)}>拒绝</a>
        </Space>
      ),
    },
  ];

  const add_friend_table_columns = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      render: (_, record) => <img src={img_util.img_translate(record?.user?.avatar)} className="table_avatar"></img>,
    },
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      render: (_, record) => <span>{record?.user?.userName}</span>,
    },
    {
      title: "申请情况",
      key: "action",
      render: (_, record) => (
        <span>
          {record.reqStatus === 0
            ? "未读"
            : record.reqStatus === 1
            ? "已读"
            : record.reqStatus === 2
            ? "已通过"
            : "已拒绝"}
        </span>
      ),
    },
  ];

  const add_group_table_columns = [
    {
      title: "群名",
      dataIndex: "groupName",
      key: "groupName",
      render: (_, record) => <p>{record.group.groupName}</p>,
    },
    {
      title: "申请时间",
      dataIndex: "sendTime",
      key: "sendTime",
    },
    {
      title: "申请情况",
      key: "action",
      render: (_, record) => (
        <span>
          {record.reqStatus === 0
            ? "未读"
            : record.reqStatus === 1
            ? "已读"
            : record.reqStatus === 2
            ? "已通过"
            : "已拒绝"}
        </span>
      ),
    },
  ];

  const tour_steps = [
    {
      title: "信息切换按钮",
      description: "点击切换个人位置与个人信息",
      target: () => switch_btn_ref.current,
    },
    {
      title: "个人数据",
      description: "点击后可对个人数据进行修改",
      placement: "right",
      target: () => control_right_contan_dom_ref.current,
    },
    {
      title: "个人菜单",
      description: "可修改个人信息，退出登录等",
      target: () => self_btn_ref.current,
    },
    {
      title: "显示栏",
      description: "会显示你能够看到的人以及你的好友列表",
      target: () => around_ref.current,
    },
    {
      title: "快捷键提示栏",
      description: "可以直接通过按下对应的快捷键进行操作",
      target: () => keygroup_ref.current,
    },
    {
      title: "广播信息",
      description: (
        <>
          <p>若有人进行广播，则在此显示广播内容</p>
          <p>（从上到下依次是：全局广播，空间广播，群聊广播）</p>
        </>
      ),
      target: () => radio_ref.current,
    },
    {
      title: "移动提示",
      description: (
        <>
          <p>使用键盘上的←↑↓→或者WASD来实现移动</p>
          <p>（所有键盘操作之前，请先点击地图）</p>
        </>
      ),
    },
  ];

  /*   ------------------------------ ---------------------  dom 变量   ------------------------- -------------------------------------    */

  const confirm_user_info_ok_handle = () => {
    set_confirm_user_info_btn_loading(true);
    let userName = setting_userName_input.current.value;
    let sendData = new FormData();
    setTimeout(() => {
      const gender_seleter = document.querySelector(".ant-select-selection-item");
      sendData.append("sex", gender_seleter.innerHTML);
      sendData.append("nickName", userName);
      let imgData = new FormData();
      imgData.append("avatar", imageFile);

      if (userName === "") {
        message.warning("昵称不可为空!");
        set_confirm_user_info_btn_loading(false);
      } else {
        patchUserInfo(sendData)
          .then((res) => {
            if (imageFile === null || imageFile === undefined) {
            } else {
              postUserAvatar(imgData)
                .then((res) => {
                  // message.destroy()
                  // message.success(res)
                  getUserInfo_update();
                  set_is_show_confirm_user_info(false);
                  set_confirm_user_info_btn_loading(false);
                })
                .catch((err) => {
                  message.destroy();
                  message.error(err);
                  set_is_show_confirm_user_info(false);
                  set_confirm_user_info_btn_loading(false);
                });
            }
            message.destroy();
            message.success(res);
            getUserInfo_update();
            set_is_show_confirm_user_info(false);
            set_confirm_user_info_btn_loading(false);
          })
          .catch((err) => {
            message.destroy();
            message.error(err);
            set_is_show_confirm_user_info(false);
            set_confirm_user_info_btn_loading(false);
          });
      }
    }, 1000);
  };

  const confirm_user_info_cancel_handle = () => {
    set_is_show_confirm_user_info(false);
    set_confirm_user_info_btn_loading(false);
  };

  // 上传按钮dom
  const uploadButton = (
    <div>
      {img_upload_loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传新头像
      </div>
    </div>
  );

  /* 
  导航栏信息设置-------
  */
  const return_controlBox_info_rightCont_position = (x, y) => {
    return (
      <>
        <span className="font_themecolor">X:</span> {x}
        <span className="font_themecolor"> Y:</span> {y}
      </>
    );
  };

  const return_controlBox_info_rightCont_ev_info = (v, e) => {
    return (
      <>
        <span className="font_themecolor">V:</span> {v}
        <span className="font_themecolor"> E:</span> {e}
      </>
    );
  };

  let controlBox_info_detail_position = (
    <>
      <button id="set_btn" onClick={transfer_handle}>
        确定传送
      </button>
      <div className="positionORev_set_box">
        <div className="positionORev_set">
          <span className="font_themecolor">X</span>:{" "}
          <input type={"number"} className="positionORev_set_input" ref={position_x_input} key="position_x"></input>
        </div>
        <div className="positionORev_set">
          <span className="font_themecolor">Y</span>:{" "}
          <input type={"number"} className="positionORev_set_input" ref={position_y_input} key="position_y"></input>
        </div>
      </div>
    </>
  );

  let controlBox_info_detail_ev_info = (
    <>
      <button id="set_btn" onClick={change_ev_handle}>
        确定修改
      </button>
      <div className="positionORev_set_box">
        <div className="positionORev_set">
          <span className="font_themecolor">V(视野)</span>:{" "}
          <input type={"number"} className="positionORev_set_input" ref={vision_input} key="vision"></input>
        </div>
        <div className="positionORev_set">
          <span className="font_themecolor">E(存在感)</span>:{" "}
          <input type={"number"} className="positionORev_set_input" ref={exist_input} key="exist"></input>
        </div>
      </div>
    </>
  );
  /* 
    导航栏信息设置------
  */

  // 个人信息拓展下拉框
  const items = [
    {
      key: "1",
      label: (
        <span target="_blank" className="menu_btn" rel="noopener noreferrer" onClick={logoutHandle}>
          退出登录
        </span>
      ),
    },
    {
      key: "2",
      label: (
        // <span target="_blank" className='menu_btn' rel="noopener noreferrer" onClick={open_user_info}>
        <span
          target="_blank"
          className="menu_btn"
          rel="noopener noreferrer"
          onClick={() => {
            set_is_show_confirm_user_info(true);
          }}
        >
          个人信息
        </span>
      ),
    },
    {
      key: "3",
      label: (
        // <span target="_blank" className='menu_btn' rel="noopener noreferrer" onClick={open_user_info}>
        <span
          target="_blank"
          className="menu_btn"
          rel="noopener noreferrer"
          onClick={() => {
            set_tour_open(true);
          }}
        >
          新手引导
        </span>
      ),
    },
  ];

  return (
    <Layout className="home_layout">
      <Header className="site-layout-background">
        {/* 查找 */}
        <div className="space_select_Box">
          <span onClick={() => set_select_space_modal_show(true)}>选择空间:{select_space_value_name}</span>
        </div>
        {/* 灵动导航 */}
        <div className="controlBox" ref={control_dom_ref}>
          <div className="switchBtn" onClick={switch_controlBox_info} ref={switch_btn_ref}>
            <EnvironmentOutlined
              className={controlBox_info_type === "position" ? "" : "hidden_switchBtn"}
              style={{ fontSize: "18px" }}
            />
            <UserOutlined
              className={controlBox_info_type === "ev_info" ? "" : "hidden_switchBtn"}
              style={{ fontSize: "18px" }}
            />
          </div>
          <div className="logoText">
            <LoadingSvg />
            ChatSpace
          </div>
          <div className="outline">
            <div className="leftCont" ref={control_left_contan_dom_ref}></div>
            <Tooltip title="点击修改" placement="right">
              <div className="rightCont" ref={control_right_contan_dom_ref} onClick={show_position_setting}>
                {rightCont_text}
              </div>
            </Tooltip>
          </div>
          <div className="detail" ref={detail_dom_ref}>
            {detailCont}
          </div>
        </div>
        <div id="header_right_box">
          {/* 实时延时信息 */}
          <div className="delay_info">
            <span
              id="delay_dots"
              style={{ backgroundColor: current_delay < 500 ? "#40ff2f" : current_delay > 1500 ? "red" : "yellow" }}
            ></span>
            {Math.abs(current_delay)}ms
          </div>
          {/* 个人信息 */}
          <Dropdown menu={{ items }} placement="bottom" trigger={["click"]}>
            <div className="userInfoBox" ref={self_btn_ref}>
              <span className="userInfo_name">{myUserName}</span>
              <Avatar size="large" src={img_util.img_translate(myAvatar)} />
            </div>
          </Dropdown>
        </div>
      </Header>
      <div className="wrapper">
        {/* 左侧信息栏 */}
        <div className="left_wrapper">
          <Around around_ref={around_ref} />
        </div>
        {/* 地图 */}
        <div className="middle_wrapper">
          <Map myUser_data={myUser_data} />
          <div className="map_around_btn_array">
            <Tooltip title="受邀信息">
              <div className="map_around_btn" onClick={show_invite_info}>
                <Badge dot={invite_info_toRead || add_friend_toRead}>
                  <MailOutlined />
                </Badge>
              </div>
            </Tooltip>
            <Tooltip title="我的申请">
              <div className="map_around_btn" onClick={show_friend_add_info}>
                <Badge dot={false}>
                  <SolutionOutlined />
                </Badge>
              </div>
            </Tooltip>
            <Tooltip title="区域创建">
              <div className="map_around_btn" onClick={show_set_area_Modal}>
                <Badge dot={false}>
                  <BlockOutlined />
                </Badge>
              </div>
            </Tooltip>
            <Tooltip title="我的群组">
              <div className="map_around_btn" onClick={show_group_Modal}>
                <Badge dot={false}>
                  <TeamOutlined />
                </Badge>
              </div>
            </Tooltip>
          </div>
        </div>
        {/* 聊天 */}
        <div className="right_wrapper">
          <div></div>
        </div>
      </div>
      <div className="absolute_wrapper">
        <Tour
          open={tour_open}
          onClose={() => {
            set_tour_open(false);
            if (is_first_enter) {
              getFirstLogin()
                .then((res) => {})
                .catch((err) => {
                  console.error(err);
                });
            }
          }}
          steps={tour_steps}
        />
        <Input_box showing={false} />
        <Chat_invite_box />
        <Chat_history_box />
        <Group_Modal showing={is_show_group_modal} getGroupList_update_function={getGroupList_update} />
        <Radio radio_ref={radio_ref} />
        <KeyGroup keygroup_ref={keygroup_ref} />
        {/* 创建聊天区域——对话框 */}
        <Modal
          title="聊天区域创建"
          open={set_area_isModalOpen}
          // onOk={set_area_handleOk}
          onCancel={set_area_handleCancel}
          // okText="创建区域"
          footer={
            <>
              <Button
                type="primary"
                onClick={() => set_area_handleOk(my_group_arr, my_zone_in_group, my_zone_create_orient)}
                loading={area_modal_loading}
              >
                创建区域
              </Button>
              <Button onClick={set_area_handleCancel}>取消</Button>
            </>
          }
          cancelText="取消"
        >
          <div className="area_set_input_box area_set_input_box_start">
            <Space>
              <span className="input_title">区域名称 :</span>
              <Input type="text" id="area_name_input" ref={set_area_name} />
            </Space>
          </div>
          <div className="area_set_input_box area_set_input_box_start">
            <Space>
              <Space>
                <span className="input_title">所属群组 :</span>
                <Select
                  defaultValue={my_group_arr[0]?.value}
                  style={{ width: 120 }}
                  onChange={my_group_arr_select_handleChange}
                  options={my_group_arr}
                />
              </Space>
              <Space>
                <span className="input_title">建立方向 :</span>
                {/* <Select
                  defaultValue={'leftBottom'}
                  style={{ width: 150 }}
                  onChange={build_area_orient_select_handleChange}
                  options={[
                    {
                      value: 'rightBottom',
                      label: '目前位置右下角'
                    }, {
                      value: 'rightTop',
                      label: '目前位置右上角'
                    }, {
                      value: 'leftBottom',
                      label: '目前位置左下角'
                    }, {
                      value: 'leftTop',
                      label: '目前位置左上角'
                    }, {
                      value: 'middle',
                      label: '以目前位置为中心'
                    },
                  ]}
                /> */}
                <span>目前位置右下角</span>
              </Space>
            </Space>
          </div>
          <div className="area_set_input_box area_set_input_box_start">
            <Space>
              <span className="input_title">横向跨度 :</span>
              <Input type="number" ref={area_width_x_ref} />
            </Space>
            <Space>
              <span className="input_title">纵向跨度 :</span>
              <Input type="number" ref={area_width_y_ref} />
            </Space>
          </div>
        </Modal>
        {/* 管理聊天区域——对话框 */}
        <Modal
          title="聊天区域管理"
          open={edit_area_isModalOpen}
          onOk={edit_area_handleOk}
          onCancel={edit_area_handleCancel}
          okText="修改区域"
          cancelText="解除区域"
          wrapClassName="edit_modal"
        >
          <div className="area_set_input_box area_set_input_box_start">
            <span className="input_title">起始坐标 :</span>
            x<input type="number" />y<input type="number" />
          </div>
          <div className="area_set_input_box">
            <span className="input_title">结束坐标 :</span>
            x<input type="number" />y<input type="number" />
          </div>
        </Modal>
        {/* 个人信息修改——对话框 */}
        <Modal
          title="个人信息修改"
          open={is_show_confirm_user_info}
          onOk={confirm_user_info_ok_handle}
          onCancel={confirm_user_info_cancel_handle}
          confirmLoading={confirm_user_info_btn_loading}
          okText="确定"
          cancelText="取消"
        >
          <>
            <div className="user_info_box">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={img_handleChange}
              >
                {myAvatar ? (
                  <img
                    // 如果有上传的图片base64则使用上传的，如果没有，则判断有无获取用户的头像
                    src={imageUrl ? imageUrl : img_util.img_translate(myAvatar)}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <div className="nickName_box input_box">
                <span className="box_label">昵称:</span>
                <input
                  ref={setting_userName_input}
                  type="text"
                  placeholder="修改昵称"
                  id="nickName_input"
                  defaultValue={setting_userName}
                  key={setting_userName}
                />
              </div>
              <div className="gender_box input_box">
                <span className="box_label">性别:</span>
                <Select
                  defaultValue={setting_gender}
                  style={{ width: 120 }}
                  onChange={gender_handleChange}
                  key={setting_gender}
                >
                  <Option value="男">男</Option>
                  <Option value="女">女</Option>
                  <Option value="保密">保密</Option>
                </Select>
              </div>
            </div>
          </>
          ,
        </Modal>
        <Modal
          title="选择空间"
          open={select_space_modal_show}
          icon={<LayoutFilled style={{ color: "#40b8c3" }} />}
          onCancel={() => {
            set_select_space_modal_show(false);
          }}
          footer={
            <>
              <Button
                type="primary"
                onClick={() => {
                  set_select_space_modal_show(false);
                  logoutHandle();
                }}
              >
                退出登录
              </Button>
            </>
          }
        >
          <Spin spinning={select_space_data_loading} size="large" tip="正在加载">
            <Space
              className="site-button-ghost-wrapper"
              wrap
              direction="vertical"
              style={{
                width: "100%",
              }}
            >
              {select_space_data?.spaceList?.map((v, i, a) => {
                return (
                  <Button
                    block
                    key={v.spaceId}
                    disabled={v.state === "NOT_OPEN"}
                    className={
                      v.state === "NOT_OPEN"
                        ? ""
                        : v.loadFactor > 80
                        ? "state_full"
                        : v.loadFactor < 30
                        ? "state_free"
                        : "state_crowded"
                    }
                    onClick={() => {
                      if (v.spaceId !== select_space_value) {
                        out_of_cur_space();
                        set_select_space_value(v.spaceId);
                        set_select_space_value_name(v.spaceName);
                        set_select_space_modal_show(false);
                      }
                    }}
                  >
                    <div className="space_option_btn_box">
                      <span className="space_option_btn_state">
                        {v.state === "NOT_OPEN"
                          ? "未开放"
                          : v.loadFactor > 80
                          ? "爆满"
                          : v.loadFactor < 30
                          ? "空闲"
                          : "拥挤"}
                      </span>
                      <span className="space_option_btn_spaceName">{v.spaceName}</span>
                      <span className="space_option_btn_onlineNum">
                        {v.onlineNum} <UserOutlined />
                      </span>
                    </div>
                  </Button>
                );
              })}
            </Space>
          </Spin>
        </Modal>
      </div>
      <div className="draw_wrapper">
        <Drawer title="受邀信息" placement="right" onClose={close_invite_info} open={invite_drawer_open} mask={true}>
          <Segmented
            block
            options={["聊天申请", "好友申请", "群组申请"]}
            onChange={(v) => {
              set_invited_info_Segmented(v);
            }}
          />
          <Space direction="vertical">
            <span></span>
          </Space>
          {invited_info_Segmented === "聊天申请" ? (
            <Table columns={invite_chat_table_columns} dataSource={invite_arr} pagination={{ defaultPageSize: 7 }} />
          ) : invited_info_Segmented === "好友申请" ? (
            <Table
              columns={friend_add_table_columns}
              dataSource={request_friend_add_arr}
              pagination={{ defaultPageSize: 7 }}
            />
          ) : (
            <Req_Table data={request_group_apply_arr} />
          )}
        </Drawer>
        <Drawer
          title="我的申请"
          placement="right"
          onClose={close_friend_add_info}
          open={friend_add_drawer_open}
          mask={true}
        >
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="已发送好友申请" key="1">
              <Table
                columns={add_friend_table_columns}
                dataSource={request_to_friend_add_arr}
                size="small"
                pagination={{ defaultPageSize: 6 }}
              />
            </Panel>
            <Panel header="已发送群组申请" key="2">
              <Table
                columns={add_group_table_columns}
                dataSource={request_group_add_arr}
                size="small"
                pagination={{ defaultPageSize: 6 }}
              />
            </Panel>
          </Collapse>
        </Drawer>
      </div>
    </Layout>
  );
};

export default Home;
