import React, { useRef, useState, useEffect } from "react";
import "../../../../assets/style/Home/Home_comp/OtherT/Chat_invite_box.scss";
import { PubSub } from "pubsub-js";
import { message } from "antd";
import * as img_util from "../../../../utils/img_handle";
import { postRequestFriend, friendDelete } from "../../../../request/request";

export default function Chat_invite_box() {
  const [showing, set_showing] = useState(false);
  const [show_friend, set_show_friend] = useState(false);
  const [is_friend, set_is_friend] = useState(false);
  const [inviting_userInfo, set_inviting_userInfo] = useState("");
  const [inviting_waiting, set_inviting_waiting] = useState("");

  useEffect(() => {
    let invite_info_token = PubSub.subscribe("invite_info", (_, stateObj) => {
      set_showing(true);
      set_show_friend(false);
      set_is_friend(stateObj.isFriend);
      set_inviting_userInfo(stateObj.target_user_info);
    });

    let friend_info_token = PubSub.subscribe("friend_info", (_, stateObj) => {
      set_showing(true);
      set_show_friend(true);
      set_inviting_userInfo(stateObj.target_user_info);
    });

    let get_inviting_ask_msg_token = PubSub.subscribe("get_inviting_ask_msg", (_, stateObj) => {
      if (stateObj?.unreachable) {
        set_inviting_waiting("无法送达");
      } else if (stateObj.inviting_ask_msg.access) {
        set_inviting_waiting("接受");
      } else {
        set_inviting_waiting("拒绝");
      }
      set_showing(false);
    });

    return () => {
      PubSub.unsubscribe(invite_info_token);
      PubSub.unsubscribe(friend_info_token);
      PubSub.unsubscribe(get_inviting_ask_msg_token);
    };
  }, []);

  useEffect(() => {
    if (inviting_waiting === "正在等待") {
      message.loading("正在邀请", 0);
    } else if (inviting_waiting === "接受") {
      message.destroy();
      message.success("接受聊天邀请");
    } else if (inviting_waiting === "拒绝") {
      message.destroy();
      message.warning("拒绝聊天邀请");
    } else if (inviting_waiting === "无法送达") {
      message.destroy();
      message.warning("邀请信息不可达！对方无法发现你");
    } else if (inviting_waiting === "隐藏等待") {
      message.destroy();
      message.warning("邀请等待已隐藏");
    } else {
      message.destroy();
    }
  }, [inviting_waiting]);

  const cancel_invite = () => {
    set_showing(false);
    set_inviting_waiting("隐藏等待");
  };

  const show_history_box = () => {
    // message.warning("正在加班开发中，请耐心等待")
    PubSub.publish("hide_history_box", { showing: true, history_info: inviting_userInfo });
    set_showing(false);
  };

  const start_chat = () => {
    PubSub.publish("send_chat_request", { targetId: inviting_userInfo.user.userId, is_friend_chat: false });
    set_inviting_waiting("正在等待");
  };

  const start_friend_chat = () => {
    PubSub.publish("send_chat_request", { targetId: inviting_userInfo.user.userId, is_friend_chat: true });
    set_inviting_waiting("正在等待");
  };

  const request_add_friend_handle = () => {
    let session_myUserId = sessionStorage.getItem("userId");

    let send_data = new FormData();
    send_data.append("senderId", parseInt(session_myUserId));
    send_data.append("targetId", inviting_userInfo.user.userId);
    send_data.append("reqSrc", "");
    send_data.append("reqRemark", "");
    postRequestFriend(send_data).then((res) => {
      // console.log(res);
      if (res.msg === "ok") message.success("申请成功");
    });
  };

  const request_delete_friend_handle = () => {
    let temp_formData = new FormData();
    temp_formData.append("toUserId", inviting_userInfo.user.userId);
    friendDelete(temp_formData).then((res) => {
      // console.log(res);
      if (res.msg === "删除好友成功") {
        message.success("删除好友成功");
        PubSub.publish("refresh_friend", { refresh: true });
      }
    });
  };

  return (
    <>
      <div className={showing ? "chat_invite_box_wrapper" : "chat_invite_box_wrapper hidden_chat_invite_box"}>
        <div className="userInfo_box">
          <img src={img_util.img_translate(inviting_userInfo?.user?.avatar)} alt="" />
          <span className="userName">{inviting_userInfo?.user?.nickName}</span>
        </div>
        <div className="button_box">
          {show_friend ? (
            <span>
              <button onClick={request_delete_friend_handle}>删除好友</button>
              <button onClick={show_history_box}>私聊记录</button>
            </span>
          ) : (
            <span>
              <button onClick={start_chat}>临时聊天</button>
              {is_friend ? (
                <button onClick={start_friend_chat}>好友私聊</button>
              ) : (
                <button onClick={request_add_friend_handle}>添加好友</button>
              )}
            </span>
          )}
          <button onClick={cancel_invite}>取消</button>
        </div>
      </div>
    </>
  );
}
