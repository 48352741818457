import React, { useState } from 'react'
import { Input, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { postCode } from '../../../request/request';

const { Search } = Input;

export default function EmailInput(props) {

    const { userName_register, setEmail_register } = props;

    const [email_loading, set_email_loading] = useState(false);
    const [email_btnText, set_email_btnText] = useState('发送验证码');

    const onSearch = (email) => {
        let sendData = new FormData();
        sendData.append('userName', userName_register);
        sendData.append('email', email);

        // 登录接口
        postCode(sendData).then(res => {
            set_email_loading(true)
            let count_number = 60;
            let intervalToken = setInterval(() => {
                if (count_number > 0) {
                    set_email_btnText(`${count_number--}s`)
                } else {
                    clearInterval(intervalToken);
                    set_email_btnText('发送验证码')
                    set_email_loading(false)
                }
            }, 1000);
            message.destroy();
            message.success(res);
        }).catch(error => {
            console.error(error);
            message.error(error)
        })
    };

    const onChange = (email) => {
        if (setEmail_register) {
            // console.log(email.target.value);
            setEmail_register(email.target.value);
        }
    }

    return (
        <>
            <Search
                placeholder="邮箱"
                allowClear
                enterButton={email_btnText}
                size="large"
                onSearch={onSearch}
                prefix={<MailOutlined className="site-form-item-icon" />}
                onChange={onChange}
                loading={email_loading}
            />
        </>
    )
}
