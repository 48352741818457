import React, { useRef, useState, useEffect } from 'react'
import '../../../../assets/style/Home/Home_comp/OtherT/Chat_history_box.scss'
import { PubSub } from 'pubsub-js';
import { getChatHistory } from '../../../../request/request';
import {
    CloseOutlined
} from '@ant-design/icons';
import { Spin, message } from 'antd';

export default function Chat_history_box() {

    const [showing, set_showing] = useState(false)
    const [history_loading, set_history_loading] = useState(false)
    const [showing_page, set_showing_page] = useState(1)
    const [history_target_info, set_history_target_info] = useState()
    const [history_message_arr, set_history_message_arr] = useState([])

    useEffect(() => {
        let hide_history_box_token = PubSub.subscribe('hide_history_box', (_, stateObj) => {
            set_showing(stateObj.showing)
            set_history_target_info(stateObj.history_info)
        })
        // let chat_status_token = PubSub.subscribe('chat_status', (_, stateObj) => {
        //     set_showing(stateObj.chatting)
        // })

        return () => {
            PubSub.unsubscribe(hide_history_box_token);
            // PubSub.unsubscribe(chat_status_token);
        }
    }, [])

    useEffect(() => {
        if (history_target_info) {
            // console.log(history_target_info);
            get_history_handle(0,true);
        }
    }, [history_target_info])

    // 获取相对页数的聊天记录
    const get_history_handle = (change_page,is_first) => {
        set_history_loading(true)
        let get_page = showing_page + change_page;
        let session_myUserId = sessionStorage.getItem('userId');
        getChatHistory({
            friendId: history_target_info.friendId,
            page: get_page
        }).then(res => {
            // console.log(res);
            set_history_loading(false)
            if (res.length === 0 && !is_first) {
                message.destroy();
                message.warning('聊天记录到底了！')
            }
            else {
                set_history_message_arr(res)
                set_showing_page(get_page)
            }
        }).catch(err => {
            console.error(err);
            set_history_loading(false)
        })
    }

    // 点击关闭聊天记录
    const hide_history_box_handle = () => {
        set_showing(false)
    }

    // 点击下一页
    const next_page_handle = () => {
        get_history_handle(+1)
    }

    // 点击上一页
    const last_page_handle = () => {
        if (showing_page === 1) {
            message.destroy();
            message.warning('无最新聊天记录！')
        } else {
            get_history_handle(-1);
        }

    }

    return (
        <>
            <div className={showing ? "chat_history_box_wrapper" : "chat_history_box_wrapper hidden_chat_history_box"}>
                <Spin spinning={history_loading}>
                    <div className="header">
                        <CloseOutlined onClick={hide_history_box_handle} />
                    </div>
                    {
                        history_message_arr.length === 0 ?
                            (
                                <div>
                                    未查询到聊天记录
                                </div>
                            )
                            :
                            history_message_arr.map((v, i, a) => {
                                return (
                                    <div className="chat_history_item_box">
                                        <span className={v.self ? 'send_user_name myself_send_user_name' : 'send_user_name'}>{v.sender.nickName}</span>:
                                        <span className="send_message_contant">{v.content}</span>
                                    </div>
                                )
                            })

                    }
                    {/* <div className="chat_history_item_box">
                    <span className= 'send_user_name myself_send_user_name'>jack</span>:
                    <span className="send_message_contant">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span>
                </div>
                <div className="chat_history_item_box">
                    <span className= 'send_user_name myself_send_user_name'>jack</span>:
                    <span className="send_message_contant">看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文看一下中文</span>
                </div> */}
                    <div className="footer">
                        <span className='page_btn' onClick={last_page_handle}>上一页</span>
                        <span>当前页数 {showing_page}</span>
                        <span className='page_btn' onClick={next_page_handle}>下一页</span>
                    </div>
                </Spin>
            </div>

        </>
    )
}
