import React, { useRef, useState, useEffect } from 'react'
import { Table, Space,message } from 'antd';

export default function Zone_Table(props) {

    let { data } = props

    // 我的群组表格格式
    const columns = [
        {
            title: '区域名',
            dataIndex: 'zoneName',
            key: 'zoneName',
        },
        {
            title: '空间',
            dataIndex: 'spaceId',
            key: 'spaceId',
        },
        {
            title: '坐标',
            dataIndex: 'locate',
            key: 'locate',
            render: (_, record) =>
                <span>{`(${record.startX},${record.startY})-(${record.endX},${record.endY})`}</span>
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <a>编辑</a> */}
                    <a onClick={() => { message.warning('删除功能还未完善，过段时间再试试') }}>删除</a>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={{ defaultPageSize: 6 }}/>
        </>
    )
}
