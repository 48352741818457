import React from "react";
import "../../assets/style/Index/Ani_cont.scss";
import main_character from '../../assets/img/Index/main_character.svg'
import guest1 from '../../assets/img/Index/guest1.svg'
import guest2 from '../../assets/img/Index/guest2.svg'
import guest3 from '../../assets/img/Index/guest3.svg'

const Ani_cont = () => {
  return (
    <div className="ani_container">
      <div className="ani_background">
        <div className="vision_circle">
            <div id="show_zone"></div>
        </div>
        <img src={main_character} alt="" id="main_character"/>
        <img src={guest1} alt="" id="guest1" className="guest"/>
        <img src={guest2} alt="" id="guest2" className="guest"/>
        <img src={guest3} alt="" id="guest3" className="guest"/>
        <p id="welcome_message">欢迎来到ChatSpace~</p>
      </div>
      <div className="ani_introduction">
        <h1>ChatSpace</h1>
        <p>快来搭建属于你自己的ChatSpace吧😊</p>
      </div>
    </div>
  );
};

export default Ani_cont;
