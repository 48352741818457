import React, { useEffect, useState } from 'react'
import { PubSub } from 'pubsub-js';
import '../../../../assets/style/Home/Home_comp/KeyItem.scss'
import keyImg from '../../../../assets/img/key.jpg'

export default function KeyItem(props) {

    let { keyText, keyIntro, baColor, isActive } = props;

    return (
        // <div className='keyItem_box'>
        //     <div className="keyItem_introduction">{keyIntro}</div>
        //     <div className="keyItem">
        //         <div className="keyItem_text">{keyText}</div>
        //         <img src={keyImg} alt="" />
        //     </div>
        // </div>
        <div className={isActive ? 'keyListItem_box isActive' : 'keyListItem_box'} style={{ 'backgroundColor': baColor }}>
            <div className="keyItem_introduction">{keyIntro}</div>
            <div className="keyItem_text">{keyText}</div>
        </div>
    )
}
