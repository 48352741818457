import React, { useState, useEffect } from "react";
import { Layout, Button, ConfigProvider, message } from "antd";
import LogoSvg from "../components/LogPage/LogoSvg";
import Ani_cont from "../components/IndexPage/Ani_cont";
import "../assets/style/Index/index.scss";
import { useNavigate } from "react-router-dom";

const { Header, Footer, Content } = Layout;

const Index = () => {
  const navigate = useNavigate();
  const [is_Mobile, set_is_Mobile] = useState(false);
  useEffect(() => {
    let ua = navigator.userAgent;
    // console.log(ua.includes('Android'));
    if (ua.includes("Android") || ua.includes("iPhone") || ua.includes("iPad")) {
      set_is_Mobile(true);
    }
  }, []);
  return (
    // 主题色
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#40b8c3",
        },
      }}
    >
      <Layout className="index_layout">
        <Header>
          <div id="logoFont">
            <LogoSvg />
          </div>
          <div id="loginBtn">
            <Button
              type="primary"
              onClick={() => {
                if (!is_Mobile) {
                  navigate("/login", { replace: true });
                } else {
                    message.info('请使用电脑端进行登录~')
                }
              }}
            >
              去登录
            </Button>
          </div>
        </Header>
        <Content>
          <Ani_cont />
        </Content>
        <Footer>
          <p>Made by IceClean & lhppppp</p>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Index;
