import React from "react";
export const MyContext = React.createContext();

function MapProvider(props) {
    const {value,children} = props;
    return <MyContext.Provider value={value}>
        {children}
    </MyContext.Provider>
}

export default MapProvider;