import React, { useEffect, useState } from "react";
import * as map_utils from "../../../../utils/map_utils.js";

export default function ZoneItem(props) {
  let { zone_info, zoneType, show_zoneName, zone_tag } = props;

  const [zone_location, set_zone_location] = useState();

  useEffect(() => {
    set_zone_location(map_utils.calculate_chat_area(zone_info));
  }, [zone_info]);

  const get_className = (type, tag) => {
    let return_className = "zone_area build_area";
    if (type === "PUBLIC") {
      return_className += " public_area";
    }
    if (tag === "deny_enter_zone") {
      return_className += " deny_enter_zone";
    } else if (tag === "gonna_enter_zone") {
      return_className += " gonna_enter_zone";
    }

    return return_className;
  };

  return (
    <>
      <div
        className={get_className(zoneType, zone_tag)}
        style={{
          left: `${zone_location?.left}`,
          top: `${zone_location?.top}`,
          width: `${zone_location?.width}`,
          height: `${zone_location?.height}`,
        }}
      >
        <div className={show_zoneName ? "zoneName_box showing" : "zoneName_box"}>{zone_info.zoneName}</div>
      </div>
    </>
  );
}
