import React, { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export default function UserNameInput(props) {

    const { setUserName_login, setUserName_register, isLogin } = props;

    const [defaultUserName_login, setDefaultUserName_login] = useState();

    // 输入框修改值同步修改
    const inputChangeHandle = (e) => {
        if (setUserName_login)
            setUserName_login(e.target.value);
        if (setUserName_register)
            setUserName_register(e.target.value);
    }

    useEffect(() => {
        if (isLogin) {
            let localStorage_userName = localStorage.getItem('ChatSpace_login_remember');
            if (localStorage_userName !== undefined) {
                setDefaultUserName_login(localStorage_userName);
                setUserName_login(localStorage_userName);
                // console.log(defaultUserName_login);
            }
        }
    }, [defaultUserName_login])

    return (
        <>
            <Input
                placeholder="用户名"
                prefix={<UserOutlined className="site-form-item-icon" />}
                size="large"
                onChange={inputChangeHandle}
                defaultValue={defaultUserName_login}
                key={defaultUserName_login}
            />
        </>
    )
}
