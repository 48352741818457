import React, { useState, useEffect } from 'react';
import '../../../assets/style/Home/Home_comp/Around.scss'
import { PubSub } from 'pubsub-js';
import * as img_util from '../../../utils/img_handle'
import { getFriendList } from '../../../request/request';


const Around = (props) => {
    const {around_ref} = props

    const [other_user_data_arr, set_other_user_data_arr] = useState([])
    const [friend_user_data_arr, set_friend_user_data_arr] = useState([])
    const [header_focus, set_header_focus] = useState('around')
    const [friend_map, set_friend_map] = useState()

    const send_my_invite_info = (e) => {
        // 验证是否在数组中
        for (let index = 0; index < other_user_data_arr.length; index++) {
            if (other_user_data_arr[index].user.userId === parseInt(e.target.id)) {
                PubSub.publish('invite_info', {
                    target_user_info: other_user_data_arr[index],
                    isFriend: friend_map.get(other_user_data_arr[index].user.userId) ? true : false
                })
            }
        }
    }

    const send_my_friend_info = (e) => {
        // 验证是否在数组中
        for (let index = 0; index < friend_user_data_arr.length; index++) {
            if (friend_user_data_arr[index].user.userId === parseInt(e.target.id)) {
                PubSub.publish('friend_info', {
                    target_user_info: friend_user_data_arr[index]
                })
            }
        }
    }

    // 监听pubsub
    useEffect(() => {

        let map_init_other_user_token = PubSub.subscribe('map_init_other_user', (_, stateObj) => {
            set_other_user_data_arr(stateObj.user_data_arr)
          })

        // 订阅修改其他用户
        let refresh_friend_token = PubSub.subscribe('refresh_friend', (_, stateObj) => {
            if (stateObj.refresh) {
                refresh_friend_list();
            }
        })

        refresh_friend_list();

        return () => {
            PubSub.unsubscribe(map_init_other_user_token)
            PubSub.unsubscribe(refresh_friend_token)
        }
    }, [])

    // 获取当前用户的所有好友
    const refresh_friend_list = () => {
        let session_myUserId = sessionStorage.getItem('userId');
        getFriendList(session_myUserId).then(res => {
            set_friend_user_data_arr(res.friendList)
            let temp_friend_map = new Map();
            for (let index = 0; index < res.friendList.length; index++) {
                temp_friend_map.set(res.friendList[index].user.userId, '1')
            }
            set_friend_map(temp_friend_map)
        })
    }

    // 点击附件的人，查看附近的人
    const show_around = () => {
        set_header_focus('around')
    }

    // 点击我的好友，查看我的好友
    const show_friend = () => {
        set_header_focus('friend')
    }

    return (
        <>
            <div className="Around_coverBox" ref={around_ref}>
                <div className="wrapperBox">
                    <div className="around_header">
                        <h1 className={header_focus === 'around' ? 'focusing' : ''} onClick={show_around}>附近的人</h1>
                        <h1 className={header_focus === 'friend' ? 'focusing' : ''} onClick={show_friend}>我的好友</h1>
                    </div>
                    <div className="around_user">
                        {
                            header_focus === 'around' ?
                                other_user_data_arr.map((v, i, a) => {
                                    return <div className="around_user_item" key={i}>
                                        <img src={img_util.img_translate(v.user.avatar)} alt="" />
                                        <span className="around_user_name" onClick={send_my_invite_info} id={v.user.userId} key={v}>{v.user.nickName}</span>
                                    </div>
                                }) :
                                friend_user_data_arr.map((v, i, a) => {
                                    return <div className="around_user_item" key={v.user.nickName}>
                                        <img src={img_util.img_translate(v.user.avatar)} alt="" />
                                        <span className="around_user_name" onClick={send_my_friend_info} id={v.user.userId} key={v}>{v.user.nickName}</span>
                                    </div>
                                })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Around;